// Library imports
import React, { useState } from 'react';
import moment from 'moment';
import { useSelector } from 'react-redux';
// Component imports
import { LoadingSpinner } from '../../components/page_components/LoadingSpinner';
import Table from '../../components/page_components/TableV2';
import AlgoSearchFilter from '../../components/page_components/SearchFilters/AlgoSearchFilter';
// Util imports
import { formatOdd } from '../../utils';

const HistoryTable = () => {
    // Redux
    const { sport, loaders, algoData } = useSelector(state => state.app);
    // Local state
    const [value, setValue] = useState('');
    const [timeframe, setTimeframe] = useState('current_season');

    const configureColumns = () => {
        const data = algoData.games
            .filter(game => teamSearchFilter(game.awayTeam) || teamSearchFilter(game.homeTeam))
            .map(game => {
                return {
                    date: moment(game.dateTime).format('MM/DD/YYYY'),
                    'algo-score': `${game.awayTeam.abbr} ${game.prediction.awayScore} vs. ${game.homeTeam.abbr} ${game.prediction.homeScore}`,
                    score: `${game.awayTeam.abbr} ${game.awayTeam.score} vs. ${game.homeTeam.abbr} ${game.homeTeam.score}`,
                    ...((sport === 'mlb' || sport === 'nhl') && {
                        moneyline: `${game.homeTeam.abbr} ${formatOdd(game.homeTeam.odds?.fullGame?.moneyline, 'moneyline')}`,
                    }),
                    ...((sport === 'mlb' || sport === 'nhl') && {
                        powerline: `${game.homeTeam.abbr} ${
                            game.prediction.homeScore < game.prediction.awayScore
                                ? ` +${game.prediction.powerMoneyline.dogML}`
                                : game.prediction.powerMoneyline.favML
                        }`,
                    }),
                    ...(sport !== 'mlb' &&
                        sport !== 'nhl' && {
                            spread: `${game.homeTeam.abbr} ${formatOdd(game.homeTeam.odds?.fullGame?.spread, 'spread')}`,
                        }),
                    ...((sport === 'mlb' || sport === 'nhl') && {
                        'algo-powerline': `${game.algoPowerlineWinner ? 'Win' : 'Loss'}`,
                    }),
                    ...(sport !== 'mlb' &&
                        sport !== 'nhl' && {
                            'algo-spread': `${game.algoSpreadCorrect ? 'Win' : 'Loss'}`,
                        }),
                    'algo-moneyline': `${game.algoMoneylineCorrect ? 'Win' : 'Loss'}`,
                };
            });
        return data;
    };

    const teamSearchFilter = team => {
        if (!value) return true;
        if (
            team.teamName.toLowerCase().includes(value.toLowerCase()) ||
            team.teamName.toLowerCase().includes(value.toLowerCase()) ||
            (team.displayName && team.displayName.toLowerCase().includes(value.toLowerCase())) ||
            (team.displayName && team.displayName.toLowerCase().includes(value.toLowerCase())) ||
            team.abbr.toLowerCase().includes(value.toLowerCase()) ||
            team.abbr.toLowerCase().includes(value.toLowerCase()) ||
            team.fullName.toLowerCase().includes(value.toLowerCase()) ||
            team.fullName.toLowerCase().includes(value.toLowerCase())
        ) {
            return true;
        } else {
            return false;
        }
    };

    if (loaders.find(loader => loader === 'algo-data')) {
        return (
            <div>
                <LoadingSpinner loading={true} />
            </div>
        );
    }

    return (
        <div>
            <AlgoSearchFilter value={value} setValue={setValue} timeframe={timeframe} setTimeframe={setTimeframe} />
            <Table
                dataList={configureColumns()}
                autoHeight={true}
                containerStyle={{ padding: '15px 25px' }}
                bordered
                cellBordered
                pagination={{
                    enabled: true,
                    pageSize: 10,
                }}
                columns={
                    sport === 'mlb' || sport === 'nhl'
                        ? [
                              { header: 'Date', dataKey: 'date', flexGrow: 1, minWidth: 125 },
                              { header: 'Algorithm', dataKey: 'algo-score', flexGrow: 2, minWidth: 200 },
                              { header: 'Score', dataKey: 'score', flexGrow: 1, minWidth: 200 },
                              { header: 'Moneyline', dataKey: 'moneyline', flexGrow: 1, minWidth: 100 },
                              { header: 'Powerline', dataKey: 'powerline', flexGrow: 1, minWidth: 100 },
                              { header: 'Algo Powerline', dataKey: 'algo-powerline', flexGrow: 1, coloredCell: true, minWidth: 100 },
                              { header: 'Algo Moneyline', dataKey: 'algo-moneyline', flexGrow: 1, coloredCell: true, minWidth: 100 },
                          ]
                        : [
                              { header: 'Date', dataKey: 'date', flexGrow: 1, minWidth: 125 },
                              { header: 'Algorithm', dataKey: 'algo-score', flexGrow: 2, minWidth: 150 },
                              { header: 'Score', dataKey: 'score', flexGrow: 1, minWidth: 200 },
                              { header: 'Spread', dataKey: 'spread', flexGrow: 1, minWidth: 100 },
                              { header: 'Algo Spread', dataKey: 'algo-spread', flexGrow: 1, coloredCell: true, minWidth: 100 },
                              { header: 'Algo Moneyline', dataKey: 'algo-moneyline', flexGrow: 1, coloredCell: true, minWidth: 100 },
                          ]
                }
            />
        </div>
    );
};

export default HistoryTable;
