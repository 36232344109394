// Library imports
import React, { useEffect, useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faListOl,
    faChartLine,
    faPercentage,
    faCalculator,
    faCloudRain,
    faBookReader,
    faFileMedical,
    faCalendar,
    faKey,
    faTools,
    faBaseballBall,
    faEquals,
    faHistory,
    faCrown,
    faLock,
} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// Component imports
import Prediction from '../game_card_windows/Prediction';
import Schedule from '../game_card_windows/Schedule';
import InjuryList from '../game_card_windows/InjuryList';
import Weather from '../game_card_windows/Weather';
import Headlines from '../game_card_windows/Headlines';
import PublicMoneyPercent from '../game_card_windows/PublicMoneyPercent';
import PickOdds from '../game_card_windows/PickOdds';
import LineData from '../game_card_windows/LineData';
import PowerRank from '../game_card_windows/PowerRank';
import KeyFactors from '../game_card_windows/KeyFactors';
import Pitchers from '../game_card_windows/Pitchers';
import Totals from '../game_card_windows/Totals';
import AdvancedSearch from '../game_card_windows/AdvancedSearch';
import Situations from '../game_card_windows/Situations';
import UpgradeTooltip from '../../../components/page_components/UpgradeTooltip';
// Styled components
import { GameCardContentWrapper } from '../game_card_windows/styles';
import { BadgeContainer, GameCardHeaderWrapper, GameCardSubtitle, GameCardTitle, GameCardTitleWrapper, LiveBadge, VIPBadge } from './styles';
import ToolTip from '../game_card_windows/PowerRank/ToolTip';

const GameCard = ({ demo = false, freePlay = false, sport, game, defaultWindow = 'power-rank', vipPlay = false, history = false, setSubpage }) => {
    // Redux
    const { user } = useSelector(state => state.user);
    // Local state
    const [activeWindow, setActiveWindow] = useState(defaultWindow);
    var startTime = moment(new Date(game.dateTime)).format('MM/DD hh:mm A');
    if (game.status === 'complete') {
        startTime = game.status;
    }
    const [window, setWindow] = useState({
        id: 'power-rank',
        title: 'Power Ranks',
        subtitle: startTime,
    });
    const windowData = [
        {
            id: 'power-rank',
            title: 'Power Ranks',
            subtitle: startTime,
        },
        {
            id: 'line-movement',
            title: 'Line Movement',
            subtitle: startTime,
        },
        {
            id: 'percent',
            title: 'Ticket Percents',
            subtitle: startTime,
        },
        {
            id: 'weather',
            title: 'Weather',
            subtitle: game.location ? game.location : startTime,
        },
        {
            id: 'prediction',
            title: 'Prediction',
            subtitle: startTime,
        },
        {
            id: 'headlines',
            title: 'Headlines',
            subtitle: startTime,
        },
        {
            id: 'injuries',
            title: 'Injury Report',
            subtitle: startTime,
        },
        {
            id: 'schedule',
            title: 'Schedule',
            subtitle: startTime,
        },
        {
            id: 'pickOdds',
            title: 'Pick Odds',
            subtitle: startTime,
        },
        {
            id: 'key-factor',
            title: 'Key Factors',
            subtitle: startTime,
        },
        {
            id: 'pitchers',
            title: 'Pitchers',
            subtitle: startTime,
        },
        {
            id: 'totals',
            title: 'Total Data',
            subtitle: startTime,
        },
        {
            id: 'search',
            title: 'Advanced Search',
            subtitle: startTime,
        },
        {
            id: 'situations',
            title: 'Situations',
            subtitle: startTime,
        },
    ];

    useEffect(() => {
        setWindow(windowData.find(data => data.id === activeWindow));
    }, [activeWindow]);

    return (
        <div className="grid-item-wrapper" key={game.gameId} id={game.gameId} style={demo ? { width: '100%', minWidth: '0px' } : {}}>
            <GameCardHeaderWrapper>
                <GameCardTitleWrapper>
                    <GameCardTitle>
                        {window.title} {window.id === 'power-rank' ? <ToolTip /> : <></>}
                    </GameCardTitle>
                    <GameCardSubtitle>{window.subtitle}</GameCardSubtitle>
                    <BadgeContainer>
                        {game.status === 'live' ? <LiveBadge bg="danger">Live</LiveBadge> : <></>}
                        {vipPlay ? <VIPBadge bg="warning">VIP Play</VIPBadge> : <></>}
                    </BadgeContainer>
                </GameCardTitleWrapper>
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    {!history ? (
                        <Button
                            variant={
                                moment(game.dateTime).isBefore(moment.now()) || demo ? 'light' : activeWindow === 'pickOdds' ? `primary` : `outline-primary`
                            }
                            disabled={moment(game.dateTime).isBefore(moment.now()) || demo || user.reciept.type === 'free-account'}
                            onClick={e => setActiveWindow(activeWindow === 'pickOdds' ? 'power-rank' : e.currentTarget.id)}
                            id="pickOdds"
                        >
                            Picks
                        </Button>
                    ) : (
                        <></>
                    )}
                </div>
            </GameCardHeaderWrapper>
            <GameCardContentWrapper>
                {activeWindow === 'power-rank' ? <PowerRank demo={demo} game={game} sport={sport} history={history} /> : <></>}
                {activeWindow === 'line-movement' ? <LineData demo={demo} game={game} sport={sport} /> : <></>}
                {activeWindow === 'percent' ? <PublicMoneyPercent game={game} sport={sport} /> : <></>}
                {activeWindow === 'weather' ? <Weather game={game} sport={sport} /> : <></>}
                {activeWindow === 'prediction' ? <Prediction game={game} sport={sport} /> : <></>}
                {activeWindow === 'headlines' ? <Headlines game={game} sport={sport} /> : <></>}
                {activeWindow === 'injuries' ? <InjuryList game={game} sport={sport} /> : <></>}
                {activeWindow === 'schedule' ? <Schedule game={game} sport={sport} history={history} /> : <></>}
                {activeWindow === 'pickOdds' ? <PickOdds key={game.gameId} game={game} sport={sport} /> : <></>}
                {activeWindow === 'key-factor' ? <KeyFactors game={game} sport={sport} /> : <></>}
                {activeWindow === 'pitchers' ? <Pitchers game={game} sport={sport} /> : <></>}
                {activeWindow === 'totals' ? <Totals game={game} sport={sport} history={history} /> : <></>}
                {activeWindow === 'search' ? <AdvancedSearch game={game} sport={sport} setSubpage={setSubpage} demo={demo} /> : <></>}
                {activeWindow === 'situations' ? <Situations game={game} setSubpage={setSubpage} demo={demo} /> : <></>}
            </GameCardContentWrapper>
            <div className="game-card-toolbar">
                <button id="power-rank" onClick={e => setActiveWindow(e.currentTarget.id)} className={activeWindow === 'power-rank' ? `active-tab` : ``}>
                    <FontAwesomeIcon icon={faListOl} />
                    <span>Power</span>
                </button>
                <button id="line-movement" onClick={e => setActiveWindow(e.currentTarget.id)} className={activeWindow === 'line-movement' ? `active-tab` : ``}>
                    <FontAwesomeIcon icon={faChartLine} />
                    <span>Lines</span>
                </button>
                <button
                    disabled={freePlay ? true : false}
                    id="percent"
                    onClick={e => setActiveWindow(e.currentTarget.id)}
                    className={activeWindow === 'percent' ? `active-tab` : ``}
                >
                    <FontAwesomeIcon icon={faPercentage} />
                    <span>Public</span>
                </button>
                <button id="key-factor" onClick={e => setActiveWindow(e.currentTarget.id)} className={activeWindow === 'key-factor' ? `active-tab` : ``}>
                    <FontAwesomeIcon icon={faKey} />
                    <span>Factors</span>
                </button>
                {sport === 'mlb' ? (
                    <button id="pitchers" onClick={e => setActiveWindow(e.currentTarget.id)} className={activeWindow === 'pitchers' ? `active-tab` : ``}>
                        <FontAwesomeIcon icon={faBaseballBall} />
                        <span>Pitchers</span>
                    </button>
                ) : (
                    <></>
                )}
                {sport !== 'nba' && sport !== 'cbb' && sport !== 'nhl' ? (
                    <button
                        disabled={true}
                        id="weather"
                        onClick={e => setActiveWindow(e.currentTarget.id)}
                        className={activeWindow === 'weather' ? `active-tab` : ``}
                    >
                        <FontAwesomeIcon icon={faCloudRain} />
                        <span>Weather</span>
                    </button>
                ) : (
                    <></>
                )}
                <button
                    disabled={freePlay || history || (demo && sport !== 'mlb') ? true : false}
                    id="situations"
                    onClick={e => setActiveWindow(e.currentTarget.id)}
                    className={activeWindow === 'situations' ? `active-tab` : ``}
                >
                    <FontAwesomeIcon icon={faTools} />
                    <span>Situations</span>
                </button>
                <button id="totals" onClick={e => setActiveWindow(e.currentTarget.id)} className={activeWindow === 'totals' ? `active-tab` : ``}>
                    <FontAwesomeIcon icon={faEquals} />
                    <span>Totals</span>
                </button>
                <button
                    disabled={freePlay ? true : false}
                    id="injuries"
                    onClick={e => setActiveWindow(e.currentTarget.id)}
                    className={activeWindow === 'injuries' ? `active-tab` : ``}
                >
                    <FontAwesomeIcon icon={faFileMedical} />
                    <span>Injuries</span>
                </button>
                <button
                    disabled={demo ? true : false}
                    id="schedule"
                    onClick={e => setActiveWindow(e.currentTarget.id)}
                    className={activeWindow === 'schedule' ? `active-tab` : ``}
                >
                    <FontAwesomeIcon icon={faCalendar} />
                    <span>Schedules</span>
                </button>
                <button id="search" onClick={e => setActiveWindow(e.currentTarget.id)} className={activeWindow === 'search' ? `active-tab` : ``}>
                    <FontAwesomeIcon icon={faHistory} />
                    <span>Adv. Search</span>
                </button>
                {!demo && user.reciept.type === 'free-account' ? (
                    <UpgradeTooltip
                        children={
                            <button id="prediction" disabled={true} onClick={() => ''}>
                                <FontAwesomeIcon icon={faLock} />
                                <span>Prediction</span>
                            </button>
                        }
                    />
                ) : (
                    <button id="prediction" onClick={e => setActiveWindow(e.currentTarget.id)} className={activeWindow === 'prediction' ? `active-tab` : ``}>
                        <FontAwesomeIcon icon={faCalculator} />
                        <span>Prediction</span>
                    </button>
                )}
                <button
                    disabled={history ? true : false}
                    id="headlines"
                    onClick={e => setActiveWindow(e.currentTarget.id)}
                    className={activeWindow === 'headlines' ? `active-tab` : ``}
                >
                    <FontAwesomeIcon icon={faBookReader} />
                    <span>Headlines</span>
                </button>
            </div>
        </div>
    );
};

export default GameCard;
