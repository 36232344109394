// Library imports
import React, { useState, useEffect, useRef } from 'react';
import { Col, Row, Card, Button, Alert, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faArrowDown } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
// Component imports
import { LoadingSpinner } from '../../../components/page_components/LoadingSpinner';
import Faq from 'react-faq-component';
// Controller imports
import { getInvestor, getPortfolio } from '../../../controllers/InvestorController';
// Styled compoennts
import { ProfileContentContainer } from '../styles';
import { LabelValueListGroup } from '../../App/styles';
// Util imports
import { FUTURES } from '../../../utils/constants';

const Investor = () => {
    // Redux
    const { user } = useSelector(state => state.user);
    // Local state
    const [loading, setLoading] = useState(false);
    const [investor, setInvestor] = useState(null);
    const [portfolio, setPortfolio] = useState(null);
    const [error, setError] = useState({ type: 'error', message: '' });
    const faqRef = useRef(null);

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const investorRes = await getInvestor(user.email);
                const portfolioRes = await getPortfolio();
                setInvestor(investorRes);
                setPortfolio(portfolioRes);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
        }
        fetchData();
    }, []);

    if (loading || !investor || !portfolio) {
        return (
            <ProfileContentContainer>
                <LoadingSpinner loading={loading} />
            </ProfileContentContainer>
        );
    }

    return (
        <ProfileContentContainer>
            <Row style={{ display: error.message ? 'flex' : 'none' }}>
                <Col sm={12} md={12} l={12} xl={12}>
                    <Alert variant={error.type}>{error.message}</Alert>
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={4} l={4} xl={4}>
                    <Card style={{ height: '100%' }}>
                        <Card.Header as="h5">Account Balance</Card.Header>
                        <Card.Body>
                            <Card.Title as="h3">${investor.balance.toFixed(2)}</Card.Title>
                            <Card.Text>
                                This is the amount of money you currently have invested into the Line Prophet investor portfolio. Deposit your funds an watch
                                you account grow. All deposits are backed up to 50%. By depsiting you are agreeing to the information in the FAQs below.
                            </Card.Text>
                            <Button variant={'success'} onClick={() => window.open('https://twitter.com/LineProphetVIP', '_blank')}>
                                {'DM on Twitter to deposit'}
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4} l={4} xl={4}>
                    <Card style={{ height: '100%' }}>
                        <Card.Header as="h5">Withdrawal Balance</Card.Header>
                        <Card.Body>
                            <Card.Title as="h3">$0.00</Card.Title>
                            <Card.Text>
                                This is the amount of money you can currently request to withdrawal. You may withdrawal any time 6 months after your final
                                investment. Withdrawals are every Friday and the max amount each week is $250.00. Subject to holds during the middle of a
                                season.
                            </Card.Text>
                            <Button disabled={true} variant="primary">
                                Withdrawal
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={4} l={4} xl={4}>
                    <Card style={{ height: '100%' }}>
                        <Card.Header as="h5">Why Invest?</Card.Header>
                        <Card.Body>
                            <Card.Text>
                                Investing in the Line Prophet sports betting portfolio means you never have to worry about line shopping, market watching, and
                                missing out on the best value bets ever again. Let our team manage your units and place them on the best possible line values.
                                With our pooled money we unlock access to higher bonuses and more risk free bets. You never have to worry about losing your
                                entire bankroll. 75% of your money is backed up by my personal bankroll.
                            </Card.Text>
                            <Button variant={'primary'} onClick={() => faqRef.current.scrollIntoView()}>
                                Learn More
                            </Button>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col sm={12} md={4} lg={4} xl={4}>
                    <Card>
                        <Card.Header as="h5">Your Profits</Card.Header>
                        <Card.Body>
                            <LabelValueListGroup variant="flush">
                                <ListGroup.Item>
                                    <label>Invested: </label>
                                    <span
                                        style={{
                                            fontSize: '3.25em',
                                            marginTop: '5px',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        $
                                        {investor.deposits.reduce(function (a, b) {
                                            return a + b.amount;
                                        }, 0)}
                                        .00
                                    </span>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Balance: </label>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: '3.25em',
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            ${investor.balance}
                                        </span>
                                        <FontAwesomeIcon
                                            style={{
                                                marginTop: '10px',
                                                marginLeft: '10px',
                                                fontSize: '2em',
                                                color:
                                                    investor.deposits.reduce(function (a, b) {
                                                        return a + b.amount;
                                                    }, 0) > investor.balance
                                                        ? 'red'
                                                        : 'green',
                                            }}
                                            icon={
                                                investor.deposits.reduce(function (a, b) {
                                                    return a + b.amount;
                                                }, 0) > investor.balance
                                                    ? faArrowDown
                                                    : faArrowUp
                                            }
                                        />
                                    </div>
                                </ListGroup.Item>
                                <ListGroup.Item>
                                    <label>Share of Profits: </label>
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <span
                                            style={{
                                                fontSize: '3.25em',
                                                marginTop: '5px',
                                                fontWeight: 'bold',
                                            }}
                                        >
                                            {investor.share.toFixed(2)}%
                                        </span>
                                    </div>
                                </ListGroup.Item>
                            </LabelValueListGroup>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={12} md={8} lg={8} xl={8}>
                    <Card>
                        <Card.Header as="h5">Portfolio Data</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col sm={12} md={12} lg={12} xl={12}>
                                    <ListGroup
                                        variant="flush"
                                        style={{ flexWrap: 'wrap', width: '100%', justifyContent: 'space-evenly' }}
                                        className=""
                                        horizontal
                                    >
                                        <ListGroup.Item style={{ border: '0px', minWidth: '200px', textAlign: 'center' }}>
                                            <h6>Total:</h6>
                                            <h3>${portfolio.total}.00</h3>
                                        </ListGroup.Item>
                                        <ListGroup.Item style={{ border: '0px', minWidth: '200px', textAlign: 'center' }}>
                                            <h6>Balance:</h6>
                                            <h3>${portfolio.balance}.00</h3>
                                        </ListGroup.Item>
                                        <ListGroup.Item
                                            variant={(portfolio.balance - portfolio.total) / portfolio.total < 0 ? 'danger' : 'success'}
                                            style={{ border: '0px', minWidth: '200px', textAlign: 'center' }}
                                        >
                                            <h6>ROI:</h6>
                                            <h3>{(((portfolio.balance - portfolio.total) / portfolio.total) * 100).toFixed(2)}%</h3>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col sm={12} md={12}>
                                    <h5>Current Invesments</h5>
                                    <Alert variant="secondary">
                                        Represents the <b>WIN</b> amount
                                    </Alert>
                                </Col>
                                <Col sm={12} md={12} lg={6} xl={6}>
                                    <LabelValueListGroup direction="row">
                                        {FUTURES.slice(0, Math.ceil(FUTURES.length / 2)).map((future, index) => {
                                            return <ListGroup.Item key={index}>{future.value}</ListGroup.Item>;
                                        })}
                                    </LabelValueListGroup>
                                </Col>
                                <Col sm={12} md={12} lg={6} xl={6}>
                                    <LabelValueListGroup direction="row">
                                        <ListGroup.Item>Texas Rangers to win AL West</ListGroup.Item>
                                        <ListGroup.Item>Braves to win World Series</ListGroup.Item>
                                        {FUTURES.slice(Math.floor(FUTURES.length / 2)).map((future, index) => (
                                            <ListGroup.Item key={index}>{future.value}</ListGroup.Item>
                                        ))}
                                    </LabelValueListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row ref={faqRef} className="mt-3 mb-3">
                <Col sm={12} md={12} lg={12} xl={12}>
                    <Card>
                        <Card.Header as="h5">FAQ</Card.Header>
                        <Card.Body>
                            <Faq
                                data={{
                                    rows: [
                                        {
                                            title: 'How are the share of profits split?',
                                            content: `The profits are split based on the amount of money you have in the fund compared to other users.  The more money you deposit the higher percentage of profits you will recieve.  The best part is your deposits are backed up to 50% of your initial balance.  Meaning you can take in limitless profits while only risking 50% of your investment.`,
                                        },
                                        {
                                            title: 'What is the minimum investment?',
                                            content:
                                                'There is no minimum investment but there is a one-time $25.00 service fee built in.  After your initial deposit there are no more service fees.  Remember though, the larger the investment ($500.00 max) the larger your share of the profits.',
                                        },
                                        {
                                            title: 'Where are my funds invested?',
                                            content:
                                                'Line Prophet LLC operates across multiple different books so you can be sure we are always investing at the best possible line value.  Let our team track the line movement and shop for the best possible time and place to invest.  Funds can be invested from everything to live bets, the member card, futures, etc. All you have to do is deposit and watch the games knowing your money is safely invested in the fund.',
                                        },
                                        {
                                            title: 'When can I withdrawal my winnings?',
                                            content: `You may withdrawal any time 6 months after your final investment.  Withdrawals are every Friday and the max amount each week is $250.00.  Subject to holds during the middle of a season.`,
                                        },
                                        {
                                            title: 'What are the benefits of investing?',
                                            content: `Investing in the Line Prophet sports betting portfolio means you never have to worry about line shopping, market watching, and missing out on the best value bets ever again.  Let our team manage your units and place them on the best possible line values.  With our pooled money we unlock access to higher bonuses and more risk free bets.  You never have to worry about losing your entire bankroll.  75% of your money is backed up by my personal bankroll.`,
                                        },
                                        {
                                            title: 'What are the deposit methods?',
                                            content:
                                                'Deposits can be made using the credit card form above.  Deposits can also be made using other methods by sending a meessage to @LineProphetVIP on Twitter.',
                                        },
                                        {
                                            title: 'What are the withdrawal methods?',
                                            content:
                                                'Withdrawals can be requested and sent through CashApp, Venmo, PayPal, or Bitcoin.  Just request a withdrawal above and a member of the LineProphet team will be with you shortly.',
                                        },
                                    ],
                                }}
                                styles={{
                                    bgColor: 'transparent',
                                    titleTextColor: '#000',
                                    rowTitleColor: '#000',
                                    padding: '60px 0px',
                                    rowContentColor: '#666',
                                    // arrowColor: "red",
                                }}
                                config={
                                    {
                                        // animate: true,
                                        // arrowIcon: "V",
                                        // tabFocus: true
                                    }
                                }
                            />
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </ProfileContentContainer>
    );
};

export default Investor;
