// Library imports
import React, { useState, useEffect } from 'react';
import { Container, Navbar, Nav } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faHistory } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
// Component imports
import StatGraphs from './StatGraphs';
import HistoryTable from './HistoryTable';
// Action imports
import { fetchAlgoStats } from '../../store/slices/appSlice';

const Algorithm = () => {
    // Redux
    const { sport, algoData } = useSelector(state => state.app);
    const dispatch = useDispatch();
    // Local state
    const [subpage, setSubpage] = useState('stats');

    useEffect(() => {
        dispatch(fetchAlgoStats());
    }, [sport]);

    return (
        <div>
            <Navbar bg="light" variant="light" style={{ border: '1px solid #ccc' }}>
                <Container>
                    <Nav className="justify-content-center w-100">
                        <Nav.Link active={subpage === 'stats'} onClick={() => setSubpage('stats')} style={{ marginRight: 15 }}>
                            <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faChartLine} /> Stats
                        </Nav.Link>
                        <Nav.Link active={subpage === 'history'} onClick={() => setSubpage('history')} style={{ marginRight: 15 }}>
                            <FontAwesomeIcon style={{ marginTop: '-2px' }} icon={faHistory} /> History
                        </Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
            {subpage === 'stats' ? <StatGraphs /> : <HistoryTable games={algoData.games} />}
        </div>
    );
};

export default Algorithm;
