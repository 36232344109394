export default {
    mlb: {
        awayTeam: {
            teamName: 'Baltimore',
            teamId: 7654,
            abbr: 'BAL',
            mascot: 'Orioles',
            fullName: 'Baltimore Orioles',
            shortName: '',
            pitcherData: 'https://www.espn.com/mlb/player/_/id/38295',
            color: '#DF4601',
            pitcher: {
                firstName: 'Dean',
                lastName: 'Kremer',
                stats: {
                    'Wins-Losses': 4,
                    'Earned Run Average': 4.39,
                    Strikeouts: 75,
                    'Walks Plus Hits Per Inning Pitched': 1.25,
                    xfip: 4.43,
                    kpercent: 21.7,
                    war: 0.5,
                    babip: 0.247,
                },
                throws: 'Right',
                splits: {
                    away: {
                        era: 3.53,
                        walks: 15,
                    },
                    home: {
                        era: 5.4,
                        walks: 16,
                    },
                },
            },
            odds: {
                fullGame: {
                    spread: -1.5,
                    moneyline: -110,
                },
            },
            spreadPercent: 83,
            mlPercent: 64,
            mlMoneyPercent: 64,
            spreadMoneyPercent: 83,
            injuries: [
                {
                    player: 'Grayson Rodriguez',
                    position: 'SP',
                    date: 'Aug 19',
                    status: '15-Day-IL',
                    desc: 'Aug 7: Manager Brandon Hyde said Wednesday that Rodriguez has been diagnosed with a mild right lat strain, Andy Kostka of TheBaltimoreBanner.com reports.',
                    value: 0,
                },
                {
                    player: 'Jacob Webb',
                    position: 'RP',
                    date: 'Aug 19',
                    status: '15-Day-IL',
                    desc: 'Aug 7: Webb (1-5) allowed a run on a hit and a walk over one inning, taking the loss Wednesday versus the Marlins.',
                    value: 0,
                },
                {
                    player: 'Heston Kjerstad',
                    position: 'LF',
                    date: 'Aug 15',
                    status: '10-Day-IL',
                    desc: 'Aug 7: The Orioles optioned Kjerstad to Triple-A Norfolk on Thursday.',
                    value: 0,
                },
                {
                    player: 'Jordan Westburg',
                    position: '3B',
                    date: 'Sep 13',
                    status: '10-Day-IL',
                    desc: "Aug 1: Orioles manager Brandon Hyde said that Westburg has been diagnosed with a fractured right hand after exiting Wednesday's 10-4 win over the Blue Jays, Jacob Calvin Meyer of The Baltimore Sun reports.",
                    value: 0,
                },
                {
                    player: 'Jorge Mateo',
                    position: '2B',
                    date: 'Sep 24',
                    status: '60-Day-IL',
                    desc: 'Jul 30: Orioles manager Brandon Hyde said Thursday that Mateo (elbow) will miss "a significant amount of time," Paige Leckie of MLB.com reports.',
                    value: 0,
                },
                {
                    player: 'Danny Coulombe',
                    position: 'RP',
                    date: 'Sep 1',
                    status: '60-Day-IL',
                    desc: 'Jul 13: The Orioles transferred Coulombe (elbow) to the 60-day injured list Saturday.',
                    value: 0,
                },
                {
                    player: 'Kyle Bradish',
                    position: 'SP',
                    date: 'Sep 1',
                    status: '60-Day-IL',
                    desc: 'Jul 11: The Orioles transferred Bradish (elbow) to the 60-day injured list Thursday, Dan Connolly of The Athletic reports.',
                    value: 0,
                },
                {
                    player: 'Tyler Wells',
                    position: 'SP',
                    date: 'May 1',
                    status: '60-Day-IL',
                    desc: 'Jun 19: Wells (elbow) underwent a UCL revision and internal brace augmentation surgery Monday, Roch Kubatko of MASNSports.com reports.',
                    value: 0,
                },
                {
                    player: 'John Means',
                    position: 'SP',
                    date: 'Sep 1',
                    status: '60-Day-IL',
                    desc: 'Jun 13: Means underwent successful Tommy John surgery Monday in Texas to repair a torn UCL in his left elbow, Jake Rill of MLB.com reports.',
                    value: 0,
                },
                {
                    player: 'Nate Webb',
                    position: 'RP',
                    date: 'Feb 1',
                    status: 'Day-To-Day',
                    desc: 'May 24: Webb will miss the rest of the season after undergoing left Achilles tendon surgery, Roch Kubatko of MASNSports.com reports.',
                    value: 0,
                },
                {
                    player: 'Felix Bautista',
                    position: 'RP',
                    date: 'Feb 1',
                    status: '60-Day-IL',
                    desc: 'May 17: Bautista (elbow) played catch Friday, Danielle Allentuck of TheBaltimoreBanner.com reports.',
                    value: 0,
                },
            ],
            openMoneyLine: -125,
            stats: {
                def: {
                    errors: 59,
                    fpct: 0.986,
                    ppg: 4.3,
                },
                off: {
                    avg: 0.255,
                    hr: 178,
                    obp: 0.319,
                    oppg: 5.09,
                    rbi: 568,
                    runs: 5.09,
                    slg: 0.453,
                },
                splits: {},
                pitching: {
                    bb: 444,
                    era: 3.89,
                    fip: 3.9,
                    homeruns: 495,
                    losses: 68,
                    saves: 47,
                    so: 119,
                    wins: 115,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 113.33333333333333,
                    totalGames: 9,
                },
                favorite: {
                    avgML: -150.21505376344086,
                    totalGames: 93,
                },
            },
            recordData: {
                ats: '62-53-0',
                awayRecord: '34-22',
                conferenceRecord: '0-0',
                divisionRecord: '0-0',
                homeRecord: '34-25',
                ou: '64-41-10',
                record: '68-47',
            },
            sos: 3,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/mlb%2F7654_mlb.png?alt=media',
            division: 'East',
            conference: 'American League',
        },
        homeTeam: {
            teamName: 'Toronto',
            teamId: 992,
            abbr: 'TOR',
            mascot: 'Blue Jays',
            fullName: 'Toronto Blue Jays',
            shortName: '',
            pitcherData: 'https://www.espn.com/mlb/player/_/id/32667',
            color: '#134A8E',
            pitcher: {
                firstName: 'Kevin',
                lastName: 'Gausman',
                stats: {
                    'Wins-Losses': 9,
                    'Earned Run Average': 4.56,
                    Strikeouts: 119,
                    'Walks Plus Hits Per Inning Pitched': 1.3,
                    xfip: 4.04,
                    kpercent: 22.4,
                    war: 1.7,
                    babip: 0.303,
                },
                throws: 'Right',
                splits: {
                    away: {
                        era: 2.74,
                        walks: 15,
                    },
                    home: {
                        era: 6.39,
                        walks: 21,
                    },
                },
            },
            odds: {
                fullGame: {
                    spread: 1.5,
                    moneyline: -125,
                },
            },
            spreadPercent: 17,
            mlPercent: 36,
            mlMoneyPercent: 36,
            spreadMoneyPercent: 17,
            injuries: [
                {
                    player: 'Genesis Cabrera',
                    position: 'RP',
                    date: 'Aug 11',
                    status: 'paternity',
                    desc: 'Aug 8: Cabrera gave up a hit and a walk while striking out one in the 10th inning Sunday to record his second save of the season in a win over the Mariners.',
                    value: 0,
                },
                {
                    player: 'Alek Manoah',
                    position: 'SP',
                    date: 'Jul 1',
                    status: '15-Day-IL',
                    desc: 'Jul 29: Manoah revealed Monday in a social media post that he had the brace removed from his surgically repaired right elbow.',
                    value: 0,
                },
                {
                    player: 'Jordan Romano',
                    position: 'RP',
                    date: 'Sep 6',
                    status: '60-Day-IL',
                    desc: 'Jul 27: Romano underwent arthroscopic surgery on his right elbow Tuesday to repair an impingement and will be shut down from throwing for at least six weeks, Keegan Matheson of MLB.com reports.',
                    value: 0,
                },
                {
                    player: 'Ricky Tiedemann',
                    position: 'SP',
                    date: 'Oct 1',
                    status: 'Day-To-Day',
                    desc: 'Jul 27: Tiedemann (forearm) will undergo Tommy John surgery Tuesday, Keegan Matheson of MLB.com reports.',
                    value: 0,
                },
                {
                    player: 'Bo Bichette',
                    position: 'SS',
                    date: 'Aug 10',
                    status: '10-Day-IL',
                    desc: 'Jul 21: no',
                    value: 0,
                },
                {
                    player: 'Payton Henry',
                    position: 'C',
                    date: 'Aug 9',
                    status: 'Day-To-Day',
                    desc: 'Jun 4: Henry signed a minor-league contract with the Blue Jays on Saturday.',
                    value: 0,
                },
            ],
            openMoneyLine: 105,
            stats: {
                def: {
                    errors: 67,
                    fpct: 0.983,
                    ppg: 4.91,
                },
                off: {
                    avg: 0.241,
                    hr: 103,
                    obp: 0.313,
                    oppg: 4.13,
                    rbi: 447,
                    runs: 4.13,
                    slg: 0.382,
                },
                splits: {},
                pitching: {
                    bb: 516,
                    era: 4.63,
                    fip: 4.61,
                    homeruns: 560,
                    losses: 52,
                    saves: 45,
                    so: 158,
                    wins: 114,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 129.7058823529412,
                    totalGames: 34,
                },
                favorite: {
                    avgML: -138.86363636363637,
                    totalGames: 66,
                },
            },
            recordData: {
                ats: '54-60-0',
                awayRecord: '25-33',
                conferenceRecord: '0-0',
                divisionRecord: '0-0',
                homeRecord: '27-29',
                ou: '62-49-3',
                record: '52-62',
            },
            sos: 24,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/mlb%2F992_mlb.png?alt=media',
            division: 'East',
            conference: 'American League',
        },
        gameId: '2024-08-09T00:07:00.000Z-992-7654',
        dateTime: '2024-08-09T00:07:00.000Z',
        status: 'not started',
        bookData: {
            moneylines: [
                {
                    awayLine: -112,
                    homeLine: -104,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
                {
                    awayLine: -115,
                    homeLine: -105,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -112,
                    homeLine: -108,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
            ],
            spreads: [
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'FanDuel',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                    color: '#2192f5',
                },
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: -1.5,
                    homeLine: 1.5,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
            ],
        },
        openingSpread: 1.5,
        headlines: [
            {
                title: 'BALTIMORE ORIOLES VS TORONTO BLUE JAYS PREDICTION, PICK & MLB ODDSACTION NETWORK\n5 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.actionnetwork.com/mlb/baltimore-orioles-vs-toronto-blue-jays-prediction-pick-mlb-odds&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAD&usg=AOvVaw36Sp8lJC0xH7ljSw0uCF04',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES VS TORONTO BLUE JAYS PREDICTION MLB PICKS 8/8/24PICKDAWGZ\n17 HOURS AGO',
                href: 'https://www.google.com/url?q=https://pickdawgz.com/mlb-picks/2024-08-08/baltimore-orioles-vs-toronto-blue-jays-prediction-mlb-picks&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAH&usg=AOvVaw2ulGylaHF5cGNw331tixha',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES AT TORONTO BLUE JAYS ODDS, PICKS AND PREDICTIONSUSA TODAY SPORTSBOOK WIRE\n1 DAY AGO',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2024/08/07/baltimore-orioles-at-toronto-blue-jays-odds-picks-and-predictions-17/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAL&usg=AOvVaw2d7iQufj2ZFkWe-GQ9ipsz',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'TORONTO BLUE JAYS VS BALTIMORE ORIOLES PREDICTION 8-8-24 MLB PICKS | SPORTS \nCHAT PLACESPORTS CHAT PLACE\n16 HOURS AGO',
                href: 'https://www.google.com/url?q=https://sportschatplace.com/mlb-picks/toronto-blue-jays-vs-baltimore-orioles-prediction-8-8-24-mlb-picks/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAP&usg=AOvVaw1SICSvMqu7WHG7UAigpjah',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES AT TORONTO BLUE JAYS ODDS, PICKS AND PREDICTIONSUSA TODAY SPORTSBOOK WIRE\n2 DAYS AGO',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2024/08/06/baltimore-orioles-at-toronto-blue-jays-odds-picks-and-predictions-16/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAT&usg=AOvVaw1O84R_9wgbHpJF8U8scGtk',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES VS TORONTO BLUE JAYS PREDICTION MLB PICKS 8/6/24PICKDAWGZ\n2 DAYS AGO',
                href: 'https://www.google.com/url?q=https://pickdawgz.com/mlb-picks/2024-08-06/baltimore-orioles-vs-toronto-blue-jays-prediction-mlb-picks&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQ0PADegQIChAX&usg=AOvVaw1bcXOZf-MZNFbO_EvQJdcY',
                description: 'PickDawgz\n2 days ago',
            },
            {
                title: 'BALTIMORE ORIOLES AT TORONTO BLUE JAYS ODDS, PICKS AND PREDICTIONSSPORTSBOOKWIRE.USATODAY.COM › 2024/08/08 › BALTIMORE-ORIOLES-AT-TORONTO-...',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2024/08/08/baltimore-orioles-at-toronto-blue-jays-odds-picks-and-predictions-18/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAQQAg&usg=AOvVaw2JcLnwCKZjwdoOIB5Zmjh5',
                description:
                    "16 hours ago   Analyzing Thursday's Baltimore Orioles at Toronto Blue Jays odds and lines, with MLB expert picks, predictions and best bets.",
            },
            {
                title: 'ORIOLES VS. BLUE JAYS PREDICTION, ODDS, PROBABLE PITCHERS ... - KRON4WWW.KRON4.COM › SPORTS › SPORTS-ILLUSTRATED › ORIOLES-VS-BLUE-JAYS-PREDICTI...',
                href: 'https://www.google.com/url?q=https://www.kron4.com/sports/sports-illustrated/7f95f06a/orioles-vs-blue-jays-prediction-odds-probable-pitchers-for-thursday-aug-8-fade-dean-kremer&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAEQAg&usg=AOvVaw2F_ypjZFpv7Z7RJbn2wJEx',
                description:
                    '3 hours ago   The Toronto Blue Jays and Baltimore Orioles will play the rubber match of a three-game set on Thursday night, and oddsmakers are expecting ...',
            },
            {
                title: "ORIOLES VS BLUE JAYS PREDICTION, PICKS & ODDS FOR TONIGHT'S MLB GAMEWWW.COVERS.COM › MLB › ORIOLES-VS-BLUE-JAYS-PREDICTION-PICKS-TONIGHT-THU...",
                href: 'https://www.google.com/url?q=https://www.covers.com/mlb/orioles-vs-blue-jays-prediction-picks-tonight-thursday-8-8-2024&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAUQAg&usg=AOvVaw1k9pO2EmEhxm-N5e5I9UJd',
                description:
                    '3 hours ago   Baltimore is 7-4 straight up against Toronto this season. Find more MLB betting trends for Orioles vs. Blue Jays. Orioles vs Blue Jays game info ...',
            },
            {
                title: 'ORIOLES VS. BLUE JAYS PREDICTION, ODDS, PICKS - AUGUST 8 | FOX SPORTSWWW.FOXSPORTS.COM › ARTICLES › MLB › ORIOLES-VS-BLUE-JAYS-PREDICTION-ODDS-...',
                href: 'https://www.google.com/url?q=https://www.foxsports.com/articles/mlb/orioles-vs-blue-jays-prediction-odds-picks-august-8&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAIQAg&usg=AOvVaw3tMYYJg-PTDXM2xUmew-T7',
                description:
                    '19 hours ago   Preview the August 8 matchup between the Baltimore Orioles and Toronto Blue Jays with odds over/under, game spread, betting lines and more.',
            },
            {
                title: 'BALTIMORE ORIOLES VS. TORONTO BLUE JAYS PREDICTION 8/8/24 MLB PICK ...WWW.TONYSPICKS.COM › FREE MLB PICKS',
                href: 'https://www.google.com/url?q=https://www.tonyspicks.com/2024/08/08/baltimore-orioles-vs-toronto-blue-jays-prediction-8-8-24-mlb-pick-today/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAcQAg&usg=AOvVaw26FoQNKZc6MD7pe235qgNx',
                description:
                    "10 hours ago   This match is fixed to take place on Thursday, August 8th, 2024 at Rogers Centre. The contest not only exemplifies two teams' different fortunes ...",
            },
            {
                title: 'ORIOLES VS BLUE JAYS PREDICTION AND PICKS FOR AUGUST 8 2024WINNERSANDWHINERS.COM › GAMES › MLB › BALTIMORE-ORIOLES-VS-TORONTO-BLUE...',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/mlb/8-8-2024/baltimore-orioles-vs-toronto-blue-jays-prediction-7650/&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAMQAg&usg=AOvVaw26F9EWccZ-W-bYY2auSGCA',
                description:
                    '8 hours ago   Get our Baltimore Orioles vs Toronto Blue Jays MLB picks, prediction, analysis, odds, and more for the 8/8/24 matchup.',
            },
            {
                title: 'BALTIMORE ORIOLES VS TORONTO BLUE JAYS PREDICTION MLB PICKS 8/8/24PICKDAWGZ.COM › MLB-PICKS › BALTIMORE-ORIOLES-VS-TORONTO-BLUE-JAYS-PREDI...',
                href: 'https://www.google.com/url?q=https://pickdawgz.com/mlb-picks/2024-08-08/baltimore-orioles-vs-toronto-blue-jays-prediction-mlb-picks&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAYQAg&usg=AOvVaw2bG1FXmrxo8S4bsr9APOpy',
                description:
                    '17 hours ago   Toronto Blue Jays vs. Baltimore Orioles 8/8/24: MLB free preview, analysis, prediction, odds and pick against the spread.',
            },
            {
                title: 'ORIOLES VS. BLUE JAYS PREDICTION, ODDS, PICKS - AUGUST 7 | FOX SPORTSWWW.FOXSPORTS.COM › ARTICLES › MLB › ORIOLES-VS-BLUE-JAYS-PREDICTION-ODDS-...',
                href: 'https://www.google.com/url?q=https://www.foxsports.com/articles/mlb/orioles-vs-blue-jays-prediction-odds-picks-august-7&sa=U&ved=2ahUKEwi0qIu3muaHAxXm48kDHZDaJcgQFnoECAAQAg&usg=AOvVaw2LSY2GrNq3kJotw4r07KVE',
                description:
                    '2 days ago   Preview the August 7 matchup between the Baltimore Orioles and Toronto Blue Jays with odds over/under, game spread, betting lines and more.',
            },
        ],
        total: 9,
        totalStats: {
            total: 9,
            over: 117,
            under: 120,
        },
        advantages: [
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 55.708047411104175,
                teamId: 7654,
                description: 'BAL has a better PPG.  Teams with a better PPG win at a 55% rate.',
                amount: 0.96,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 53.903810118675835,
                teamId: 7654,
                description: 'BAL has a better DPPG.  Teams with a better DPPG win at a 53% rate.',
                amount: 0.6100000000000003,
            },
            {
                adv: 'Key Factor - AVG',
                id: 'key-factor',
                factorId: 'avg',
                winPercent: 55.55555555555556,
                teamId: 7654,
                description: 'BAL has a better AVG.  Teams with a better AVG win at a 55% rate.',
                amount: 0.014000000000000012,
            },
            {
                adv: 'Key Factor - HR',
                id: 'key-factor',
                factorId: 'hr',
                winPercent: 54.06790518898143,
                teamId: 7654,
                description: 'BAL has a better HR.  Teams with a better HR win at a 54% rate.',
                amount: 75,
            },
            {
                adv: 'Key Factor - OBP',
                id: 'key-factor',
                factorId: 'obp',
                winPercent: 54.694391934467546,
                teamId: 7654,
                description: 'BAL has a better OBP.  Teams with a better OBP win at a 54% rate.',
                amount: 0.006000000000000005,
            },
            {
                adv: 'Key Factor - RBI(s)',
                id: 'key-factor',
                factorId: 'rbi',
                winPercent: 55.72232645403376,
                teamId: 7654,
                description: 'BAL has a better RBI(s).  Teams with a better RBI(s) win at a 55% rate.',
                amount: 121,
            },
            {
                adv: 'Key Factor - SLG',
                id: 'key-factor',
                factorId: 'slg',
                winPercent: 54.76635514018692,
                teamId: 7654,
                description: 'BAL has a better SLG.  Teams with a better SLG win at a 54% rate.',
                amount: 0.07100000000000001,
            },
            {
                adv: 'Key Factor - ERs',
                id: 'key-factor',
                factorId: 'errors',
                winPercent: 52.03197868087941,
                teamId: 7654,
                description: 'BAL has a better ERs.  Teams with a better ERs win at a 52% rate.',
                amount: 8,
            },
            {
                adv: 'Key Factor - FPCT',
                id: 'key-factor',
                factorId: 'fpct',
                winPercent: 52.361111111111114,
                teamId: 7654,
                description: 'BAL has a better FPCT.  Teams with a better FPCT win at a 52% rate.',
                amount: 0.0030000000000000027,
            },
            {
                adv: 'Key Factor - ERA',
                id: 'key-factor',
                factorId: 'Earned Run Average',
                winPercent: 49.96734160679295,
                teamId: 7654,
                description: 'BAL has a better ERA.  Teams with a better ERA win at a 49% rate.',
                amount: 0.16999999999999993,
            },
            {
                adv: 'Key Factor - WHIP',
                id: 'key-factor',
                factorId: 'Walks Plus Hits Per Inning Pitched',
                winPercent: 51.477347340774784,
                teamId: 7654,
                description: 'BAL has a better WHIP.  Teams with a better WHIP win at a 51% rate.',
                amount: 0.050000000000000044,
            },
            {
                adv: 'Key Factor - Ks',
                id: 'key-factor',
                factorId: 'Strikeouts',
                winPercent: 54.266666666666666,
                teamId: 992,
                description: 'TOR has a better Ks.  Teams with a better Ks win at a 54% rate.',
                amount: 44,
            },
            {
                adv: 'Key Factor - BABIP',
                id: 'key-factor',
                factorId: 'babip',
                winPercent: 52.16763005780347,
                teamId: 7654,
                description: 'BAL has a better BABIP.  Teams with a better BABIP win at a 52% rate.',
                amount: 0.055999999999999994,
            },
            {
                adv: 'Key Factor - K%',
                id: 'key-factor',
                factorId: 'kpercent',
                winPercent: 54.96402877697841,
                teamId: 992,
                description: 'TOR has a better K%.  Teams with a better K% win at a 54% rate.',
                amount: 0.6999999999999993,
            },
            {
                adv: 'Key Factor - WAR',
                id: 'key-factor',
                factorId: 'war',
                winPercent: 50.22488755622189,
                teamId: 992,
                description: 'TOR has a better WAR.  Teams with a better WAR win at a 50% rate.',
                amount: 1.2,
            },
            {
                adv: 'Key Factor - xFIP',
                id: 'key-factor',
                factorId: 'xfip',
                winPercent: 52.12383009359252,
                teamId: 992,
                description: 'TOR has a better xFIP.  Teams with a better xFIP win at a 52% rate.',
                amount: 0.3899999999999997,
            },
            {
                adv: 'Key Factor - Saves',
                id: 'key-factor',
                factorId: 'saves',
                winPercent: 53.428377460964015,
                teamId: 7654,
                description: 'BAL has a better Saves.  Teams with a better Saves win at a 53% rate.',
                amount: 2,
            },
            {
                adv: 'Key Factor - Ks',
                id: 'key-factor',
                factorId: 'so',
                winPercent: 47.647058823529406,
                teamId: 992,
                description: 'TOR has a better Ks.  Teams with a better Ks win at a 47% rate.',
                amount: 39,
            },
            {
                adv: 'Key Factor - ERA',
                id: 'key-factor',
                factorId: 'era',
                winPercent: 52.80548628428927,
                teamId: 7654,
                description: 'BAL has a better ERA.  Teams with a better ERA win at a 52% rate.',
                amount: 0.7399999999999998,
            },
            {
                adv: 'Key Factor - FIP',
                id: 'key-factor',
                factorId: 'fip',
                winPercent: 53.421052631578945,
                teamId: 7654,
                description: 'BAL has a better FIP.  Teams with a better FIP win at a 53% rate.',
                amount: 0.7100000000000004,
            },
            {
                adv: 'Key Factor - Walks',
                id: 'key-factor',
                factorId: 'bb',
                winPercent: 53.61407919547454,
                teamId: 7654,
                description: 'BAL has a better Walks.  Teams with a better Walks win at a 53% rate.',
                amount: 72,
            },
            {
                adv: 'Key Factor - HR Allowed',
                id: 'key-factor',
                factorId: 'homeruns',
                winPercent: 54.56821026282853,
                teamId: 7654,
                description: 'BAL has a better HR Allowed.  Teams with a better HR Allowed win at a 54% rate.',
                amount: 65,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 55.708047411104175,
                teamId: 7654,
                description: 'BAL has a better PPG.  Teams with a better PPG win at a 55% rate.',
                amount: 0.96,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 53.903810118675835,
                teamId: 7654,
                description: 'BAL has a better DPPG.  Teams with a better DPPG win at a 53% rate.',
                amount: 0.6100000000000003,
            },
            {
                adv: 'Key Factor - AVG',
                id: 'key-factor',
                factorId: 'avg',
                winPercent: 55.55555555555556,
                teamId: 7654,
                description: 'BAL has a better AVG.  Teams with a better AVG win at a 55% rate.',
                amount: 0.014000000000000012,
            },
            {
                adv: 'Key Factor - HR',
                id: 'key-factor',
                factorId: 'hr',
                winPercent: 54.06790518898143,
                teamId: 7654,
                description: 'BAL has a better HR.  Teams with a better HR win at a 54% rate.',
                amount: 75,
            },
            {
                adv: 'Key Factor - OBP',
                id: 'key-factor',
                factorId: 'obp',
                winPercent: 54.694391934467546,
                teamId: 7654,
                description: 'BAL has a better OBP.  Teams with a better OBP win at a 54% rate.',
                amount: 0.006000000000000005,
            },
            {
                adv: 'Key Factor - RBI(s)',
                id: 'key-factor',
                factorId: 'rbi',
                winPercent: 55.72232645403376,
                teamId: 7654,
                description: 'BAL has a better RBI(s).  Teams with a better RBI(s) win at a 55% rate.',
                amount: 121,
            },
            {
                adv: 'Key Factor - SLG',
                id: 'key-factor',
                factorId: 'slg',
                winPercent: 54.76635514018692,
                teamId: 7654,
                description: 'BAL has a better SLG.  Teams with a better SLG win at a 54% rate.',
                amount: 0.07100000000000001,
            },
            {
                adv: 'Key Factor - ERs',
                id: 'key-factor',
                factorId: 'errors',
                winPercent: 52.03197868087941,
                teamId: 7654,
                description: 'BAL has a better ERs.  Teams with a better ERs win at a 52% rate.',
                amount: 8,
            },
            {
                adv: 'Key Factor - FPCT',
                id: 'key-factor',
                factorId: 'fpct',
                winPercent: 52.361111111111114,
                teamId: 7654,
                description: 'BAL has a better FPCT.  Teams with a better FPCT win at a 52% rate.',
                amount: 0.0030000000000000027,
            },
            {
                adv: 'Key Factor - ERA',
                id: 'key-factor',
                factorId: 'Earned Run Average',
                winPercent: 49.96734160679295,
                teamId: 7654,
                description: 'BAL has a better ERA.  Teams with a better ERA win at a 49% rate.',
                amount: 0.16999999999999993,
            },
            {
                adv: 'Key Factor - WHIP',
                id: 'key-factor',
                factorId: 'Walks Plus Hits Per Inning Pitched',
                winPercent: 51.477347340774784,
                teamId: 7654,
                description: 'BAL has a better WHIP.  Teams with a better WHIP win at a 51% rate.',
                amount: 0.050000000000000044,
            },
            {
                adv: 'Key Factor - Ks',
                id: 'key-factor',
                factorId: 'Strikeouts',
                winPercent: 54.266666666666666,
                teamId: 992,
                description: 'TOR has a better Ks.  Teams with a better Ks win at a 54% rate.',
                amount: 44,
            },
            {
                adv: 'Key Factor - BABIP',
                id: 'key-factor',
                factorId: 'babip',
                winPercent: 52.16763005780347,
                teamId: 7654,
                description: 'BAL has a better BABIP.  Teams with a better BABIP win at a 52% rate.',
                amount: 0.055999999999999994,
            },
            {
                adv: 'Key Factor - K%',
                id: 'key-factor',
                factorId: 'kpercent',
                winPercent: 54.96402877697841,
                teamId: 992,
                description: 'TOR has a better K%.  Teams with a better K% win at a 54% rate.',
                amount: 0.6999999999999993,
            },
            {
                adv: 'Key Factor - WAR',
                id: 'key-factor',
                factorId: 'war',
                winPercent: 50.22488755622189,
                teamId: 992,
                description: 'TOR has a better WAR.  Teams with a better WAR win at a 50% rate.',
                amount: 1.2,
            },
            {
                adv: 'Key Factor - xFIP',
                id: 'key-factor',
                factorId: 'xfip',
                winPercent: 52.12383009359252,
                teamId: 992,
                description: 'TOR has a better xFIP.  Teams with a better xFIP win at a 52% rate.',
                amount: 0.3899999999999997,
            },
            {
                adv: 'Key Factor - Saves',
                id: 'key-factor',
                factorId: 'saves',
                winPercent: 53.428377460964015,
                teamId: 7654,
                description: 'BAL has a better Saves.  Teams with a better Saves win at a 53% rate.',
                amount: 2,
            },
            {
                adv: 'Key Factor - Ks',
                id: 'key-factor',
                factorId: 'so',
                winPercent: 47.647058823529406,
                teamId: 992,
                description: 'TOR has a better Ks.  Teams with a better Ks win at a 47% rate.',
                amount: 39,
            },
            {
                adv: 'Key Factor - ERA',
                id: 'key-factor',
                factorId: 'era',
                winPercent: 52.80548628428927,
                teamId: 7654,
                description: 'BAL has a better ERA.  Teams with a better ERA win at a 52% rate.',
                amount: 0.7399999999999998,
            },
            {
                adv: 'Key Factor - FIP',
                id: 'key-factor',
                factorId: 'fip',
                winPercent: 53.421052631578945,
                teamId: 7654,
                description: 'BAL has a better FIP.  Teams with a better FIP win at a 53% rate.',
                amount: 0.7100000000000004,
            },
            {
                adv: 'Key Factor - Walks',
                id: 'key-factor',
                factorId: 'bb',
                winPercent: 53.61407919547454,
                teamId: 7654,
                description: 'BAL has a better Walks.  Teams with a better Walks win at a 53% rate.',
                amount: 72,
            },
            {
                adv: 'Key Factor - HR Allowed',
                id: 'key-factor',
                factorId: 'homeruns',
                winPercent: 54.56821026282853,
                teamId: 7654,
                description: 'BAL has a better HR Allowed.  Teams with a better HR Allowed win at a 54% rate.',
                amount: 65,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'BAL has an advantage based on our power rank data.',
                teamId: 7654,
                amount: 42,
            },
        ],
        prediction: {
            awayScore: 6.41,
            homeScore: 3.8,
            winner: 7654,
            spreadWinner: 7654,
            powerSpread: 2.6100000000000003,
            powerMoneyline: {
                dogML: 152,
                favML: -157,
            },
            powerLineWinner: 7654,
        },
        situations: [
            {
                id: 'division-game',
                label: 'Division Game',
                value: true,
                description: 'This is a East division game.',
                type: 'other',
            },
            {
                id: 'conference-game',
                label: 'Conference Game',
                value: true,
                description: 'This is a American League conference game.',
                type: 'other',
            },
            {
                id: 'line-movement',
                label: 'Line Movement',
                teamId: 7654,
                description: 'The line is on the move in favor of Baltimore.',
                type: 'team',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 7654,
                description: 'The Baltimore Orioles win 50.00% of games on Thursday.',
                type: 'team',
                queryString: '?test-string',
            },
            {
                id: 'team-days-of-week',
                label: 'Win % by Day of Week',
                teamId: 992,
                description: 'The Toronto Blue Jays win 50.00% of games on Thursday.',
                type: 'team',
                queryString: '?test-string',
            },
        ],
    },
    nba: {
        awayTeam: {
            teamName: 'Sacramento',
            teamId: 23549,
            abbr: 'SAC',
            mascot: 'Kings',
            fullName: 'Sacramento Kings',
            shortName: '',
            pitcherData: '',
            color: '#5A2D81',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nba%2F23549_nba.png?alt=media&token=10728d30-eb65-401f-b07b-48a7102e1c33',
            odds: {
                fullGame: {
                    moneyline: -130,
                    spread: -2.5,
                },
                half: {
                    spread: -1,
                    moneyline: -125,
                },
                playerProps: [
                    {
                        id: 'points',
                        player: 'Mitchell',
                        value: 22.5,
                    },
                    {
                        id: 'points',
                        player: 'Barnes',
                        value: 17.5,
                    },
                    {
                        id: 'rebounds',
                        player: 'Jones',
                        value: 7.5,
                    },
                    {
                        id: 'rebounds',
                        player: 'Lyles',
                        value: 7.5,
                    },
                    {
                        id: 'assists',
                        player: 'Mitchell',
                        value: 7.5,
                    },
                    {
                        id: 'threes',
                        player: 'Mitchell',
                        value: 2.5,
                    },
                ],
            },
            spread: -2.5,
            spreadPercent: 51,
            mlPercent: 59,
            mlMoneyPercent: 23,
            spreadMoneyPercent: 61,
            injuries: [
                {
                    player: "De'Aaron Fox",
                    position: 'PG',
                    date: 'Mar 31',
                    status: 'Out',
                    desc: 'Fox (hand) is out Friday against the Rockets.',
                    value: 17.6,
                },
                {
                    player: 'Richaun Holmes',
                    position: 'C',
                    date: 'Mar 18',
                    status: 'Out',
                    desc: 'Holmes has been ruled out for the remainder of the season due to personal reasons, Brenden Nunes of The Sacramento Bee reports.',
                    value: 17.86,
                },
                {
                    player: 'Terence Davis',
                    position: 'SG',
                    date: 'Feb 1',
                    status: 'Out',
                    desc: 'Davis (wrist) underwent surgery Tuesday and is out for a minimum of three months, James Ham of ESPN 1320 Sacramento reports.',
                    value: 14.72,
                },
                {
                    player: 'Domantas Sabonis',
                    position: 'PF',
                    date: 'Mar 21',
                    status: 'Out',
                    desc: "Sabonis has been diagnosed with a left bruised knee and won't travel with the team on the upcoming five-game road trip, Adrian Wojnarowski of ESPN reports.",
                    value: 22.08,
                },
            ],
            openMoneyLine: -145,
            moneyLine: -141,
            stats: {
                def: {
                    defEff: 1.118,
                    fouls: 19,
                    oppThreePoint: 36.9,
                    ppg: 115.8,
                    reboundPercent: 48.8,
                },
                off: {
                    assists: 23.6,
                    astTo: 1.75,
                    floorPercent: 49.2,
                    freeThrow: 76.8,
                    offEff: 1.067,
                    oppg: 110.5,
                    paceofplay: 7,
                    threePoint: 34.4,
                    turnovers: 13.5,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 243.70212765957447,
                    totalGames: 47,
                },
                favorite: {
                    avgML: -177.77272727272728,
                    totalGames: 22,
                },
            },
            recordData: {
                ats: '36-39-2',
                awayRecord: '12-26',
                homeRecord: '16-23',
                ou: '22-42-1',
                record: '28-49',
            },
            sos: 23,
            strength: 0,
            division: 'Pacific',
            conference: 'Western Conference',
        },
        homeTeam: {
            teamName: 'Houston',
            teamId: 24454,
            abbr: 'HOU',
            mascot: 'Rockets',
            fullName: 'Houston Rockets',
            shortName: '',
            pitcherData: '',
            color: '#CE1141',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nba%2F24454_nba.png?alt=media&token=b2e26d8f-21d0-461c-be02-7979f2b7935d',
            odds: {
                fullGame: {
                    moneyline: 115,
                    spread: 2.5,
                },
                half: {
                    spread: 1,
                    moneyline: 125,
                },
                playerProps: [
                    {
                        id: 'points',
                        player: 'Green',
                        value: 22.5,
                    },
                    {
                        id: 'points',
                        player: 'Porter',
                        value: 19.5,
                    },
                    {
                        id: 'assists',
                        player: 'Porter',
                        value: 7.5,
                    },
                    {
                        id: 'threes',
                        player: 'Green',
                        value: 3.5,
                    },
                    {
                        id: 'threes',
                        player: 'Porter',
                        value: 2.5,
                    },
                ],
            },
            spread: 2.5,
            spreadPercent: 49,
            mlPercent: 41,
            mlMoneyPercent: 77,
            spreadMoneyPercent: 39,
            injuries: [
                {
                    player: 'Trevelin Queen',
                    position: 'G',
                    date: 'Mar 24',
                    status: 'Out',
                    desc: '',
                    value: 18.31,
                },
                {
                    player: 'Alperen Sengun',
                    position: 'C',
                    date: 'Mar 31',
                    status: 'Day-To-Day',
                    desc: 'Sengun (lower leg) is questionable Friday against the Kings, Alykhan Bijani of The Athletic reports.',
                    value: 16.07,
                },
                {
                    player: 'Eric Gordon',
                    position: 'SG',
                    date: 'Mar 30',
                    status: 'Out',
                    desc: 'Gordon (groin/rest) has been ruled out for the rest of the season, Jonathan Feigen of the Houston Chronicle reports.',
                    value: 12.22,
                },
                {
                    player: 'Christian Wood',
                    position: 'C',
                    date: 'Mar 30',
                    status: 'Out',
                    desc: 'Wood (hamstring) has been shut down for the remainder of the season, Jonathan Feigen of the Houston Chronicle reports.',
                    value: 19.25,
                },
                {
                    player: 'Dennis Schroder',
                    position: 'PG',
                    date: 'Mar 30',
                    status: 'Out',
                    desc: 'Schroder (shoulder) has been ruled out for the remainder of the season, Jonathan Feigen of the Houston Chronicle reports.',
                    value: 13.21,
                },
                {
                    player: 'John Wall',
                    position: 'PG',
                    date: 'Feb 15',
                    status: 'Out',
                    desc: "Wall has recently been working on his conditioning and ramping up his workouts in Miami, but he's not expected to play for the Rockets at any point this season, Kelly Iko of The Athletic reports.",
                    value: 15.46,
                },
            ],
            openMoneyLine: 120,
            moneyLine: 115,
            stats: {
                def: {
                    defEff: 1.127,
                    fouls: 20.6,
                    oppThreePoint: 34.9,
                    ppg: 117.7,
                    reboundPercent: 48.9,
                },
                off: {
                    assists: 23.5,
                    astTo: 1.49,
                    floorPercent: 47.7,
                    freeThrow: 71.5,
                    offEff: 1.046,
                    oppg: 109.3,
                    paceofplay: 2,
                    threePoint: 34.9,
                    turnovers: 15.8,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 344.95238095238096,
                    totalGames: 63,
                },
                favorite: {
                    avgML: -144.71428571428572,
                    totalGames: 7,
                },
            },
            recordData: {
                ats: '32-43-2',
                awayRecord: '9-30',
                homeRecord: '11-27',
                ou: '32-18-1',
                record: '20-57',
            },
            sos: 30,
            strength: 1.5,
            division: 'Southwest',
            conference: 'Western Conference',
        },
        gameId: '2022-04-01T20:00:00-05:00-24454-23549',
        dateTime: '2022-04-01T20:00:00-05:00',
        status: 'not started',
        openingSpread: 2,
        bookData: [
            {
                line: -2.5,
                label: 'Bet MGM',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                color: '#ae996a',
            },
            {
                line: -2.5,
                label: 'DraftKings',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                color: '#62af23',
            },
            {
                line: -3,
                label: 'FanDuel',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                color: '#2192f5',
            },
            {
                line: -2.5,
                label: 'Points Bet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-pointsbet.png',
                color: '#000',
            },
            {
                line: -2.5,
                label: 'Caesars',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-williamhill.png',
                color: '#183432',
            },
            {
                line: -3,
                label: 'Sugar House',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betrivers.png',
                color: '#18365e',
            },
            {
                line: -3,
                label: 'UniBet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-unibet.png',
                color: '#147b44',
            },
        ],
        headlines: [
            {
                title: 'SACRAMENTO KINGS VS. HOUSTON ROCKETS PREDICTION, PREVIEW, AND ODDS',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/nba/4-1-2022/sacramento-kings-vs-houston-rockets-prediction-7268/&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAoQAg&usg=AOvVaw0PaB1MXwKb4TdOtUYQv9pb',
                description:
                    '4 hours ago   The Houston Rockets (20-57) are hosting the Sacramento Kings (28-49) for the second time in three days on Friday in a Western Conference ...',
            },
            {
                title: 'SACRAMENTO KINGS VS. HOUSTON ROCKETS PREDICTION, PREVIEW, AND ODDS',
                href: 'https://www.google.com/url?q=https://statsalt.com/games/nba/4-1-2022/sacramento-kings-vs-houston-rockets-prediction-5737/&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAkQAg&usg=AOvVaw2oWxBVTaGbwBRUg2mYK_8T',
                description:
                    '20 hours ago   The Sacramento Kings remain in Houston to play the Rockets in a back-to-back after playing each other on Wednesday.',
            },
            {
                title: 'ROCKETS VS. KINGS ODDS, LINE, SPREAD: 2022 NBA PICKS, MARCH 30 ...',
                href: 'https://www.google.com/url?q=https://www.cbssports.com/nba/news/rockets-vs-kings-odds-line-spread-2022-nba-picks-march-30-predictions-from-proven-computer-model/&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAgQAg&usg=AOvVaw2asGVcDujX790WIHClHM8k',
                description:
                    '2 days ago   Sacramento is favored by 2.5-points in the latest Rockets vs. Kings odds from Caesars Sportsbook, while the over-under is set at 229.5. Before ...',
            },
            {
                title: 'KINGS VS ROCKETS PREDICTION, BETTING ODDS, LINES & SPREAD | MARCH 30',
                href: 'https://www.google.com/url?q=https://www.fanduel.com/theduel/posts/kings-vs-rockets-prediction-odds-moneyline-spread-over-under-march-30-01fzcndd9m7n&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAcQAg&usg=AOvVaw1zqedy1Vvin88tVFFbtK9d',
                description: '2 days ago   The Rockets are underdogs by 2.5 points at home against the Kings. Houston is -110 ...',
            },
            {
                title: 'HOUSTON VS SACRAMENTO: THE ROCKETS ARE MUCH BETTER THAN THE KINGS ...',
                href: 'https://www.google.com/url?q=https://www.telecomasia.net/sports-betting/tips/basketball/nba/houston-vs-sacramento-prediction-betting-tips-odds-31-march-2022/&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAMQAg&usg=AOvVaw2K-na8LfHEGb-I6n7BwRbN',
                description:
                    '2 days ago   Prediction: Houston will be closer to win a productive matchup. Betting options: H1 (+2.5) for 1.90;; W1 (incl. OT) for 2.17;; W1+TO (230.5) ...',
            },
            {
                title: 'HOUSTON ROCKETS VS SACRAMENTO KINGS: PREDICTION ON 2 APRIL 2022',
                href: 'https://www.google.com/url?q=https://scores24.live/en/basketball/m-02-04-2022-houston-rockets-sacramento-kings-prediction&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAAQAg&usg=AOvVaw1IopYOuATGlcrDWZnhsUgJ',
                description:
                    '5 hours ago   Houston looks very decent in the final part of the regular championship, and there is no doubt that he will have time to please the fans with ...',
            },
            {
                title: 'HOUSTON ROCKETS VS SACRAMENTO KINGS: PREDICTION ON 31 MARCH 2022',
                href: 'https://www.google.com/url?q=https://scores24.live/en/basketball/m-31-03-2022-houston-rockets-sacramento-kings-prediction&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAQQAg&usg=AOvVaw0wsplhi5M6vhVHDAZEqY8w',
                description:
                    '2 days ago   Houston Rockets Sacramento Kings Prediction ... The guests are obliged to finish this duel in their favor despite all the problems. For Houston, ...',
            },
            {
                title: 'HOUSTON ROCKETS VS SACRAMENTO KINGS PREDICTION & BETTING TIPS',
                href: 'https://www.google.com/url?q=https://www.bettingexpert.com/basketball/houston-rockets-vs-sacramento-kings&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAYQAg&usg=AOvVaw0kKc2sOxoojlm-JcjL5DvD',
                description:
                    'Houston Rockets are now playing in a decent form with 2 wins and 3 losses in their last 5 matches, while Sacramento Kings are now playing in a decent good ...',
            },
            {
                title: 'SACRAMENTO KINGS VS HOUSTON ROCKETS PREDICTIONS 4/1/2022',
                href: 'https://www.google.com/url?q=https://sportsjaw.com/nba/picks/1731764/sacramento-kings-vs-houston-rockets-prediction-2022-4-1&sa=U&ved=2ahUKEwjo-bGfj_P2AhVAnGoFHaUKA2cQFnoECAUQAg&usg=AOvVaw3zFk-kuX5Mbc4hujveU6X-',
                description:
                    '1 day ago   Sacramento Kings 28-49 (36-39-2 ATS) are playing Houston Rockets 20-57 (32-43-2 ATS) on April 01, 2022, 8:00 PM ET at Toyota Center.',
            },
        ],
        total: 232.5,
        totalStats: {
            total: 232.5,
            over: 7,
            under: 9,
        },
        history: {
            winner: 23549,
            spreadWinner: 24454,
            dateTime: '2022-03-30T20:00:00-05:00',
            awayTeam: {
                teamName: 'Sacramento',
                teamId: 23549,
                score: 121,
                spread: -3.5,
                moneyLine: -145,
                color: '#5A2D81',
            },
            homeTeam: {
                teamName: 'Houston',
                teamId: 24454,
                score: 118,
                spread: 3.5,
                moneyLine: 120,
                color: '#CE1141',
            },
        },
        advantages: [
            {
                adv: 'Line Movement',
                id: 'line-movement',
                description: 'The line is moving in favor of SAC.',
                teamId: 23549,
            },
            {
                adv: 'Big Money',
                id: 'big-money',
                description: '',
                teamId: 0,
            },
            {
                adv: 'Healthier',
                id: 'health',
                description: 'The SAC have less guys on the injury report.',
                teamId: 23549,
            },
            {
                adv: 'Key Factor - AST:TO%',
                id: 'key-factor',
                factorId: 'astTo',
                winPercent: 49.90328820116054,
                teamId: 23549,
                description: 'SAC has a better AST:TO%.  Teams with a better AST:TO% win at a 49% rate.',
                amount: 0.26,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 47.10280373831775,
                teamId: 23549,
                description: 'SAC has a better PPG.  Teams with a better PPG win at a 47% rate.',
                amount: 1.2000000000000028,
            },
            {
                adv: 'Key Factor - Floor %',
                id: 'key-factor',
                factorId: 'floorPercent',
                winPercent: 47.776726584673604,
                teamId: 23549,
                description: 'SAC has a better Floor %.  Teams with a better Floor % win at a 47% rate.',
                amount: 1.5,
            },
            {
                adv: 'Key Factor - oEff%',
                id: 'key-factor',
                factorId: 'offEff',
                winPercent: 47.8448275862069,
                teamId: 23549,
                description: 'SAC has a better oEff%.  Teams with a better oEff% win at a 47% rate.',
                amount: 0.020999999999999908,
            },
            {
                adv: 'Key Factor - 3 Point %',
                id: 'key-factor',
                factorId: 'threePoint',
                winPercent: 46.10591900311526,
                teamId: 24454,
                description: 'HOU has a better 3 Point %.  Teams with a better 3 Point % win at a 46% rate.',
                amount: 0.5,
            },
            {
                adv: 'Key Factor - Free Throw %',
                id: 'key-factor',
                factorId: 'freeThrow',
                winPercent: 50.61349693251533,
                teamId: 23549,
                description: 'SAC has a better Free Throw %.  Teams with a better Free Throw % win at a 50% rate.',
                amount: 5.299999999999997,
            },
            {
                adv: 'Key Factor - Rebound %',
                id: 'key-factor',
                factorId: 'reboundPercent',
                winPercent: 47.72296015180266,
                teamId: 24454,
                description: 'HOU has a better Rebound %.  Teams with a better Rebound % win at a 47% rate.',
                amount: 0.10000000000000142,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 50.46641791044776,
                teamId: 23549,
                description: 'SAC has a better DPPG.  Teams with a better DPPG win at a 50% rate.',
                amount: 1.9000000000000057,
            },
            {
                adv: 'Key Factor - Eff%',
                id: 'key-factor',
                factorId: 'defEff',
                winPercent: 50.32558139534884,
                teamId: 24454,
                description: 'HOU has a better Eff%.  Teams with a better Eff% win at a 50% rate.',
                amount: 0.008999999999999897,
            },
            {
                adv: 'Key Factor - Fouls PG',
                id: 'key-factor',
                factorId: 'fouls',
                winPercent: 51.78294573643411,
                teamId: 23549,
                description: 'SAC has a better Fouls PG.  Teams with a better Fouls PG win at a 51% rate.',
                amount: 1.6000000000000014,
            },
            {
                adv: 'Key Factor - Opp. 3 Point %',
                id: 'key-factor',
                factorId: 'oppThreePoint',
                winPercent: 45.110410094637224,
                teamId: 24454,
                description: 'HOU has a better Opp. 3 Point %.  Teams with a better Opp. 3 Point % win at a 45% rate.',
                amount: 2,
            },
            {
                adv: 'Road Favorites',
                id: 'road-favorites',
                teamId: 23549,
                winPercent: 51.9280205655527,
                description: 'Road favorites cover 51% of the time.',
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'SAC has an advantage based on our power rank data.',
                teamId: 23549,
                amount: 0,
            },
        ],
        prediction: {
            awayScore: 118.27,
            homeScore: 115.73,
            winner: 23549,
            spreadWinner: 23549,
            powerSpread: 2.539999999999992,
            powerMoneyline: {
                dogML: 161,
                favML: -180,
            },
            powerLineWinner: 23549,
            rating: 2.25,
        },
        weather: {},
        situations: [],
    },
    nfl: {
        awayTeam: {
            teamName: 'Cleveland',
            teamId: 558812,
            abbr: 'CLE',
            mascot: 'Browns',
            fullName: 'Cleveland Browns',
            shortName: 'Cleveland',
            pitcherData: '',
            color: '#311D00',
            odds: {
                fullGame: {
                    moneyline: 130,
                    spread: 3,
                },
            },
            spread: 3,
            moneyPercent: 28,
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nfl%2F558812_nfl.png?alt=media&token=812c152a-e8a3-4ea9-8e4f-7c0961313186',
            injuries: [
                {
                    player: 'Mack Wilson',
                    position: 'LB',
                    date: 'Jan 2',
                    status: 'Out',
                    desc: "Wilson has been ruled out of Monday night's game against the Steelers, Mary Kay Cabot of The Cleveland Plain Dealer reports.",
                },
                {
                    player: 'Troy Hill',
                    position: 'CB',
                    date: 'Jan 1',
                    status: 'Out',
                    desc: "Hill (knee) has been ruled out for Monday's game against the Steelers, Scott Petrak of the Elyria Chronicle-Telegram reports.",
                },
                {
                    player: 'Ronnie Harrison Jr.',
                    position: 'S',
                    date: 'Jan 1',
                    status: 'Out',
                    desc: "Harrison (ankle) has been ruled out for Monday's game against the Steelers, Scott Petrak of the Elyria Chronicle-Telegram reports.",
                },
                {
                    player: 'John Johnson III',
                    position: 'S',
                    date: 'Jan 1',
                    status: 'Out',
                    desc: "Johnson (hamstring) has been ruled out for Monday's game against the Steelers, Scott Petrak of the Elyria Chronicle-Telegram reports.",
                },
                {
                    player: 'Kareem Hunt',
                    position: 'RB',
                    date: 'Jan 1',
                    status: 'Questionable',
                    desc: "Hunt (ankle) is listed as questionable for Monday night's game against the Steelers, Mary Kay Cabot of The Cleveland Plain Dealer reports.",
                },
                {
                    player: 'Malik Jackson',
                    position: 'DT',
                    date: 'Dec 30',
                    status: 'Questionable',
                    desc: '',
                },
                {
                    player: 'Elijah Lee',
                    position: 'LB',
                    date: 'Dec 27',
                    status: 'Out',
                    desc: 'Lee (undisclosed) was placed on the reserve/COVID-19 list Monday.',
                },
                {
                    player: 'Drew Forbes',
                    position: 'G',
                    date: 'Dec 27',
                    status: 'Injured Reserve',
                    desc: 'Forbes was activated from the reserve/COVID-19 list Monday.',
                },
                {
                    player: 'Alex Taylor',
                    position: 'OT',
                    date: 'Dec 26',
                    status: 'Out',
                    desc: 'Taylor (undisclosed) was placed on the reserve/COVID-19 list Sunday, Nate Ulrich of the Akron Beacon Journal reports.',
                },
                {
                    player: 'Takkarist McKinley',
                    position: 'DE',
                    date: 'Dec 21',
                    status: 'Injured Reserve',
                    desc: 'McKinley (Achilles) was placed on injured reserve Tuesday.',
                },
                {
                    player: 'Tony Fields II',
                    position: 'LB',
                    date: 'Dec 17',
                    status: 'Out',
                    desc: 'Fields was placed on the reserve/COVID-19 list Friday.',
                },
                {
                    player: 'Stephen Carlson',
                    position: 'TE',
                    date: 'Dec 16',
                    status: 'Injured Reserve',
                    desc: 'Carlson (knee) was activated from the reserve/COVID-19 list Thursday.',
                },
                {
                    player: 'Jack Conklin',
                    position: 'OT',
                    date: 'Dec 6',
                    status: 'Injured Reserve',
                    desc: 'Conklin (kneecap) underwent surgery to repair his ruptured patellar tendon, and is in good spirits according to head coach Kevin Stefanski, Scott Petrak of the Elyria Chronicle-Telegram reports.',
                },
                {
                    player: 'Chris Hubbard',
                    position: 'OT',
                    date: 'Oct 12',
                    status: 'Injured Reserve',
                    desc: 'Hubbard was placed on IR Tuesday.',
                },
                {
                    player: 'Montrel Meander',
                    position: 'LB',
                    date: 'Aug 25',
                    status: 'Injured Reserve',
                    desc: 'The Browns placed Meander on injured reserve Tuesday after undergoing surgery to repair his Achilles tendon, Anthony Poisal of ClevelandBrowns.com reports.',
                },
                {
                    player: 'Ryan Switzer',
                    position: 'WR',
                    date: 'Aug 17',
                    status: 'Injured Reserve',
                    desc: 'Switzer will undergo foot surgery, Scott Petrak of the Elyria Chronicle-Telegram reports.',
                },
                {
                    player: 'Tre Harbison',
                    position: 'RB',
                    date: 'Aug 9',
                    status: 'Injured Reserve',
                    desc: "Harbison (concussion) reverted to injured reserve Saturday, per the NFL's official transactions report.",
                },
            ],
            openMoneyLine: -150,
            moneyLine: 130,
            spreadPercent: 51,
            mlPercent: 59,
            mlMoneyPercent: 23,
            spreadMoneyPercent: 61,
            stats: {
                def: {
                    ppg: 21.9,
                    pypg: 215.1,
                    rypg: 105.9,
                    ypg: 321,
                },
                off: {
                    avgPenaltyYards: 60,
                    oppg: 20.9,
                    thirdDown: 39.34,
                    toRatio: 3,
                    top: 30,
                    ypg: 362.1,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 155.71428571428572,
                    totalGames: 7,
                },
                favorite: {
                    avgML: -328.125,
                    totalGames: 8,
                },
            },
            recordData: {
                ats: '5-8',
                awayRecord: '2-4',
                homeRecord: '5-2',
                ou: '18-22-1',
                record: '7-6',
            },
            sos: 18,
            division: 'North',
            conference: 'American Football Conference',
        },
        homeTeam: {
            teamName: 'Pittsburgh',
            teamId: 5929,
            abbr: 'PIT',
            mascot: 'Steelers',
            fullName: 'Pittsburgh Steelers',
            shortName: 'Pittsburgh',
            pitcherData: '',
            color: '#FFB612',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nfl%2F5929_nfl.png?alt=media&token=16305e94-eba6-4308-b0ab-751162b273c3',
            odds: {
                fullGame: {
                    moneyline: -130,
                    spread: -3,
                },
            },
            spread: -3,
            spreadPercent: 49,
            mlPercent: 41,
            mlMoneyPercent: 77,
            spreadMoneyPercent: 39,
            injuries: [
                {
                    player: 'Buddy Johnson',
                    position: 'LB',
                    date: 'Jan 1',
                    status: 'Out',
                    desc: "Johnson (foot) Brooke Pryor of ESPN.com of Monday's game against the Browns.",
                },
                {
                    player: 'Chris Wormley',
                    position: 'DT',
                    date: 'Dec 31',
                    status: 'Out',
                    desc: 'Wormley (undisclosed) was placed on the reserve/COVID-19 list Friday.',
                },
                {
                    player: 'Joe Schobert',
                    position: 'LB',
                    date: 'Dec 31',
                    status: 'Out',
                    desc: 'Schobert (undisclosed) was placed on the reserve/COVID-19 list Friday.',
                },
                {
                    player: 'Arthur Maulet',
                    position: 'CB',
                    date: 'Dec 30',
                    status: 'Out',
                    desc: 'Maulet (undisclosed) was placed on the reserve/COVID-19 list Thursday.',
                },
                {
                    player: 'Kendrick Green',
                    position: 'C',
                    date: 'Dec 30',
                    status: 'Questionable',
                    desc: '',
                },
                {
                    player: 'Pressley Harvin III',
                    position: 'P',
                    date: 'Dec 28',
                    status: 'Questionable',
                    desc: '',
                },
                {
                    player: 'Anthony McFarland Jr.',
                    position: 'RB',
                    date: 'Dec 23',
                    status: 'Out',
                    desc: 'The Steelers placed McFarland (undisclosed) on the reserve/COVID-19 list Thursday.',
                },
                {
                    player: 'Devin Bush',
                    position: 'LB',
                    date: 'Dec 22',
                    status: 'Out',
                    desc: "The Steelers placed Bush (undisclosed) on the reserve/COVID-19 list Wednesday, Teresa Varley of the Steelers' official site reports.",
                },
                {
                    player: 'Kevin Dotson',
                    position: 'G',
                    date: 'Dec 22',
                    status: 'Injured Reserve',
                    desc: "Dotson (ankle) was designated to return from injured reserve Wednesday, Teresa Varley of the Steelers' official site reports.",
                },
                {
                    player: 'Zach Banner',
                    position: 'OT',
                    date: 'Dec 22',
                    status: 'Out',
                    desc: 'The Steelers placed Banner (undisclosed) on the reserve/COVID-19 list Wednesday.',
                },
                {
                    player: 'B.J. Finney',
                    position: 'C',
                    date: 'Dec 15',
                    status: 'Injured Reserve',
                    desc: 'Finney (back) was placed on injured reserve Wednesday, Dale Lolley of DKPittsburghSports.com reports.',
                },
                {
                    player: 'Eric Ebron',
                    position: 'TE',
                    date: 'Nov 27',
                    status: 'Injured Reserve',
                    desc: 'Ebron (knee) was placed on injured reserve Saturday, Brooke Pryor of ESPN.com reports.',
                },
                {
                    player: 'JuJu Smith-Schuster',
                    position: 'WR',
                    date: 'Oct 17',
                    status: 'Injured Reserve',
                    desc: '',
                },
                {
                    player: 'Tyson Alualu',
                    position: 'DE',
                    date: 'Sep 20',
                    status: 'Injured Reserve',
                    desc: 'The Steelers placed Alualu (ankle) on injured reserve Monday.',
                },
                {
                    player: 'Stephon Tuitt',
                    position: 'DE',
                    date: 'Sep 1',
                    status: 'Injured Reserve',
                    desc: "Tuitt was placed on injured reserve for an undisclosed reason Wednesday, Teresa Varley of the Steelers' official site reports.",
                },
                {
                    player: 'Joshua Dobbs',
                    position: 'QB',
                    date: 'Aug 31',
                    status: 'Injured Reserve',
                    desc: 'The Steelers placed Dobbs (toe) on injured reserve Tuesday, Ed Bouchette of The Athletic reports.',
                },
                {
                    player: 'DeMarkus Acy',
                    position: 'CB',
                    date: 'Aug 5',
                    status: 'Injured Reserve',
                    desc: 'Acy (knee) reverted to IR on Thursday, NFL reporter Aaron Wilson reports.',
                },
            ],
            openMoneyLine: 130,
            moneyLine: -150,
            stats: {
                def: {
                    ppg: 24.7,
                    pypg: 225.7,
                    rypg: 142.7,
                    ypg: 368.4,
                },
                off: {
                    avgPenaltyYards: 49,
                    oppg: 20.1,
                    thirdDown: 40.2,
                    toRatio: -1,
                    top: 29,
                    ypg: 330.9,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 212.77777777777777,
                    totalGames: 9,
                },
                favorite: {
                    avgML: -215.5,
                    totalGames: 6,
                },
            },
            recordData: {
                ats: '5-8',
                awayRecord: '2-4',
                homeRecord: '4-2-1',
                ou: '43-24-1',
                record: '6-6-1',
            },
            sos: 10,
            division: 'North',
            conference: 'American Football Conference',
        },
        gameId: '2022-01-03T19:15:00-06:00-5929-558812',
        dateTime: '2022-01-03T19:15:00-06:00',
        status: 'not started',
        location: 'Heinz Field, Pittsburgh',
        openingSpread: 1.5,
        bookData: [
            {
                line: 8,
                label: 'Bet MGM',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                color: '#ae996a',
            },
            {
                line: 8.5,
                label: 'DraftKings',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                color: '#62af23',
            },
            {
                line: 8.5,
                label: 'FanDuel',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                color: '#2192f5',
            },
            {
                line: 8.5,
                label: 'Points Bet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-pointsbet.png',
                color: '#000',
            },
            {
                line: 8.5,
                label: 'Caesars',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-williamhill.png',
                color: '#183432',
            },
            {
                line: 8.5,
                label: 'Sugar House',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betrivers.png',
                color: '#18365e',
            },
            {
                line: 8.5,
                label: 'UniBet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-unibet.png',
                color: '#147b44',
            },
        ],
        weather: {
            forecast: {
                desc: 'Fair',
                temperature: '27°F',
                wind_speed: '10',
                wind_dir: 'NW',
            },
            location: 'Heinz Field, 100 Art Rooney Ave, Pittsburgh, Pennsylvania 15212, United States',
            address: 'Heinz Field, Pittsburgh',
        },
        headlines: [
            {
                title: 'BROWNS VS. STEELERS ODDS, LINE, SPREAD: MONDAY NIGHT FOOTBALL PICKS ...',
                href: 'https://www.google.com/url?q=https://www.cbssports.com/nfl/news/browns-vs-steelers-odds-line-spread-monday-night-football-picks-prediction-from-model-on-134-96-roll/&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAAQAg&usg=AOvVaw1-5BQMFsPTJBfubCS2WNYA',
                description:
                    '3 hours ago   Will the Browns come out fighting, or will the Steelers continue their home dominance against Cleveland? Kickoff from Pittsburgh is set for 8:15 ...',
            },
            {
                title: 'MONDAY NIGHT FOOTBALL ODDS, SPREAD, LINE: STEELERS VS. BROWNS ...',
                href: 'https://www.google.com/url?q=https://www.cbssports.com/nfl/news/monday-night-football-odds-spread-line-steelers-vs-browns-prediction-nfl-picks-from-expert-on-51-29-roll/&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAoQAg&usg=AOvVaw3mufI5baUXesXa16k3fdLF',
                description:
                    '5 hours ago   Monday Night Football odds, spread, line: Steelers vs. Browns prediction, NFL picks from expert on 51-29 roll. By CBS Sports Staff. 2 hrs ago 3 min read.',
            },
            {
                title: 'CLEVELAND BROWNS AT PITTSBURGH STEELERS ODDS, PICKS AND PREDICTION',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2022/01/03/cleveland-browns-at-pittsburgh-steelers-odds-picks-and-prediction/&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAcQAg&usg=AOvVaw3ZPvF7Ri8rvxWi-MEytjqq',
                description:
                    "6 hours ago   Analyzing Monday's Cleveland Browns at Pittsburgh Steelers Week 17 odds and lines, with expert NFL picks, predictions and best bets.",
            },
            {
                title: 'MONDAY NIGHT FOOTBALL: CLEVELAND BROWNS VS. PITTSBURGH STEELERS ...',
                href: 'https://www.google.com/url?q=https://athlonsports.com/nfl/monday-night-football-cleveland-browns-vs-pittsburgh-steelers-prediction-picks-2022&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAkQAg&usg=AOvVaw1tSNd6ZqxPI7iZHst2eKNy',
                description:
                    '16 hours ago   Our NFL experts predict, pick and preview the Cleveland Browns vs. Pittsburgh Steelers Monday night game with kickoff time, TV channel and ...',
            },
            {
                title: 'BROWNS VS STEELERS MONDAY NIGHT FOOTBALL PICKS AND PREDICTIONS',
                href: 'https://www.google.com/url?q=https://www.covers.com/nfl/browns-vs-steelers-week-17-picks-predictions-january-3-2022&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAgQAg&usg=AOvVaw1SrKpdo1WpYZpGbs0leqd8',
                description:
                    '3 hours ago   Browns vs Steelers predictions. Prediction: Browns ML (+130); Prediction: Under 43 (-110); Best bet: Harris Over 18.5 carries (+100).',
            },
            {
                title: 'CLEVELAND BROWNS: 5 BOLD PREDICTIONS VS. PITTSBURGH STEELERS IN ...',
                href: 'https://www.google.com/url?q=https://dawgpounddaily.com/2022/01/02/5-cleveland-browns-bold-predictions-week-17/&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAIQAg&usg=AOvVaw2MZnRgzzrmhNRcFOdjU3rn',
                description:
                    '1 day ago   The Cleveland Browns and Pittsburgh Steelers will play Monday night with numerous storylines and both teams pushing for a playoff birth.',
            },
            {
                title: 'CLEVELAND BROWNS VS. PITTSBURGH STEELERS ... - WINNERS AND WHINERS',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/nfl/1-3-2022/cleveland-browns-vs-pittsburgh-steelers-prediction-7316/&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAEQAg&usg=AOvVaw2qDih4fZNEbvQEyGzGhggk',
                description:
                    '4 hours ago   Monday, January 3, 2022 at 8:15pm EST Heinz Field, Pittsburgh Written by Chris King. Prediction; Trends. A pair ...',
            },
            {
                title: 'CLEVELAND BROWNS VS. PITTSBURGH STEELERS PREDICTION ... - STAT SALT',
                href: 'https://www.google.com/url?q=https://statsalt.com/games/nfl/1-3-2022/cleveland-browns-vs-pittsburgh-steelers-prediction-2318/&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAUQAg&usg=AOvVaw1aLDggA5VjoNI-UHsyas0E',
                description:
                    '2 hours ago   Cleveland Browns vs. Pittsburgh Steelers - Prediction, Preview and Odds. Monday, January 3, 2022 at 8:15pm. Written by Bosun Akinpelu on Dec 31 at 7:00pm ...',
            },
            {
                title: 'PITTSBURGH STEELERS VS CLEVELAND BROWNS PICKS AND ODDS WEEK 17',
                href: 'https://www.google.com/url?q=https://www.wagertalk.com/news/nfl/pittsburgh-steelers-vs-cleveland-browns-picks-and-odds-week-17/&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAQQAg&usg=AOvVaw27GPmGBRvPGjennCBc4YyK',
                description:
                    '18 hours ago   WagerTalk NFL handicapper Tony Mejia offers his Pittsburgh Steelers vs Cleveland Browns Week 17 betting preview for Monday, January 3.',
            },
            {
                title: 'NFL ODDS, PICKS, PREDICTIONS FOR BROWNS VS. STEELERS - ACTION NETWORK',
                href: 'https://www.google.com/url?q=https://www.actionnetwork.com/nfl/nfl-odds-picks-predictions-browns-vs-steelers-expert-guide-betting-monday-night-football&sa=U&ved=2ahUKEwiujJK5qpb1AhX2kWoFHSQxC2UQFnoECAMQAg&usg=AOvVaw2iHqnIH5965tF_zQs5lvDq',
                description:
                    '18 hours ago   Overall, Cleveland has been a league-average offense (15th in EPA per play), even when adjusted for opponent (14th in DVOA). The Browns still ...',
            },
        ],
        total: 43.5,
        totalStats: {
            total: 43.5,
            over: 7,
            under: 2,
        },
        advantages: [
            {
                adv: 'Line Movement',
                id: 'line-movement',
                description: 'The line is moving in favor of CLE.',
                teamId: 558812,
            },
            {
                adv: 'Big Money',
                id: 'big-money',
                description: '',
                teamId: 0,
            },
            {
                adv: 'Healthier',
                id: 'health',
                description: '',
                teamId: 0,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 63.34661354581673,
                teamId: 558812,
                description: 'CLE has a better PPG.  Teams with a better PPG win at a 63% rate.',
                amount: 0.7999999999999972,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 52.916666666666664,
                teamId: 558812,
                description: 'CLE has a better DPPG.  Teams with a better DPPG win at a 52% rate.',
                amount: 2.8000000000000007,
            },
            {
                adv: 'Key Factor - YPG',
                id: 'key-factor',
                factorId: 'ypg',
                winPercent: 64.90384615384616,
                teamId: 558812,
                description: 'CLE has a better YPG.  Teams with a better YPG win at a 64% rate.',
                amount: 31.200000000000045,
            },
            {
                adv: 'Key Factor - 3rd %',
                id: 'key-factor',
                factorId: 'thirdDown',
                winPercent: 62.67942583732058,
                teamId: 5929,
                description: 'PIT has a better 3rd %.  Teams with a better 3rd % win at a 62% rate.',
                amount: 0.8599999999999994,
            },
            {
                adv: 'Key Factor - Time of Poss.',
                id: 'key-factor',
                factorId: 'top',
                winPercent: 55.62130177514793,
                teamId: 558812,
                description: 'CLE has a better Time of Poss..  Teams with a better Time of Poss. win at a 55% rate.',
                amount: 1,
            },
            {
                adv: 'Key Factor - Avg. Penalty YPG',
                id: 'key-factor',
                factorId: 'avgPenaltyYards',
                winPercent: 56.86274509803921,
                teamId: 5929,
                description: 'PIT has a better Avg. Penalty YPG.  Teams with a better Avg. Penalty YPG win at a 56% rate.',
                amount: 11,
            },
            {
                adv: 'Key Factor - RYPG',
                id: 'key-factor',
                factorId: 'rypg',
                winPercent: 56.25,
                teamId: 558812,
                description: 'CLE has a better RYPG.  Teams with a better RYPG win at a 56% rate.',
                amount: 36.79999999999998,
            },
            {
                adv: 'Key Factor - PYPG',
                id: 'key-factor',
                factorId: 'pypg',
                winPercent: 49.166666666666664,
                teamId: 558812,
                description: 'CLE has a better PYPG.  Teams with a better PYPG win at a 49% rate.',
                amount: 10.599999999999994,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'PIT has an advantage based on our power rank data.',
                teamId: 5929,
                amount: 0,
            },
        ],
        prediction: {
            awayScore: 17.53,
            homeScore: 26.83,
            rating: 4.87,
            winner: 5929,
            spreadWinner: 5929,
            powerSpread: 9.299999999999997,
            powerMoneyline: {
                dogML: 403,
                favML: -403,
            },
            powerLineWinner: 5929,
        },
        situations: [],
    },
    cfb: {
        awayTeam: {
            teamName: 'LSU',
            teamId: 770,
            abbr: 'LSU',
            mascot: '',
            fullName: 'LSU Tigers',
            shortName: '',
            pitcherData: '',
            color: '#461D7C',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/cfb%2F770_cfb.svg?alt=media&token=7949f293-e0e7-4210-9173-b193ddac23b2',
            spread: 7,
            odds: {
                fullGame: {
                    moneyline: 200,
                    spread: 7,
                },
            },
            spreadPercent: 31,
            mlPercent: 17,
            moneyPercent: 17,
            injuries: [
                {
                    player: 'T.\n                                                                                    Davis-Price',
                    position: 'RB',
                    date: 'Tues ',
                    status: 'Out',
                    desc: ' Personal',
                },
                {
                    player: 'S.\n                                                                                    Ryan',
                    position: 'DB',
                    date: 'Tues ',
                    status: 'Ques',
                    desc: ' Undisclosed',
                },
                {
                    player: 'C.\n                                                                                    Wire',
                    position: 'T',
                    date: 'Tues ',
                    status: 'Ques',
                    desc: ' Lower Body',
                },
                {
                    player: 'M.\n                                                                                    Smith',
                    position: 'DL',
                    date: 'Tues ',
                    status: 'Ques',
                    desc: ' Undisclosed',
                },
                {
                    player: 'N.\n                                                                                    Farrell Jr.',
                    position: 'DT',
                    date: 'Tues ',
                    status: 'Out',
                    desc: ' Personal',
                },
                {
                    player: 'M.\n                                                                                    Burns',
                    position: 'DB',
                    date: 'indefinitely ',
                    status: 'Out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'J.\n                                                                                    Evans',
                    position: 'DT',
                    date: 'indefinitely ',
                    status: 'Out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'C.\n                                                                                    Hines',
                    position: 'G',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'A.\n                                                                                    Bradford',
                    position: 'OL',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'A.\n                                                                                    Gaye',
                    position: 'DE',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'E.\n                                                                                    Ricks',
                    position: 'CB',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'K.\n                                                                                    Boutte',
                    position: 'WR',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'D.\n                                                                                    Stingley Jr.',
                    position: 'CB',
                    date: 'indefinitely ',
                    status: 'Out',
                    desc: ' Foot',
                },
                {
                    player: 'J.\n                                                                                    Small',
                    position: 'LB',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Undisclosed',
                },
                {
                    player: 'A.\n                                                                                    Anthony',
                    position: 'DE',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Knee',
                },
                {
                    player: 'J.\n                                                                                    Emery Jr.',
                    position: 'RB',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Academics',
                },
                {
                    player: 'M.\n                                                                                    Brennan',
                    position: 'QB',
                    date: 'indefinitely ',
                    status: 'Out',
                    desc: ' Arm',
                },
            ],
            openMoneyLine: -140,
            moneyLine: 220,
            stats: {
                def: {
                    ppg: 27,
                    pypg: 234.9,
                    rypg: 137.3,
                    ypg: 372.2,
                },
                off: {
                    avgPenaltyYards: 32,
                    oppg: 26.5,
                    thirdDown: 37.36,
                    toRatio: -1,
                    top: 29,
                    ypg: 373.5,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 481.6666666666667,
                    totalGames: 6,
                },
                favorite: {
                    avgML: -1322,
                    totalGames: 5,
                },
            },
            recordData: {
                ats: '6-6',
                awayRecord: '1-4',
                homeRecord: '5-2',
                ou: '22-42-1',
                record: '6-6',
            },
            sos: 16,
        },
        homeTeam: {
            teamName: 'Kansas State',
            teamId: 912348,
            abbr: 'KSU',
            mascot: '',
            fullName: 'Kansas State Wildcats',
            shortName: 'Kansas St.',
            pitcherData: '',
            color: '#512888',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/cfb%2F912348_cfb.svg?alt=media&token=4fd7656e-540d-4a3b-bf42-365903d87fab',
            spread: -7,
            spreadPercent: 69,
            mlPercent: 83,
            moneyPercent: 83,
            injuries: [
                {
                    player: 'S.\n                                                                                    Thompson',
                    position: 'QB',
                    date: 'Tues ',
                    status: 'Prob',
                    desc: ' Ankle',
                },
                {
                    player: 'J.\n                                                                                    McPherson',
                    position: 'DB',
                    date: 'Tues ',
                    status: 'Ques',
                    desc: ' Personal',
                },
                {
                    player: 'K.\n                                                                                    Duke',
                    position: 'DE',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Leg',
                },
                {
                    player: 'J.\n                                                                                    Rubley',
                    position: 'QB',
                    date: 'indefinitely ',
                    status: 'Out',
                    desc: ' Undisclosed',
                },
            ],
            openMoneyLine: 120,
            moneyLine: -260,
            odds: {
                fullGame: {
                    moneyline: -260,
                    spread: -7,
                },
            },
            stats: {
                def: {
                    ppg: 20.9,
                    pypg: 221.7,
                    rypg: 126.3,
                    ypg: 348,
                },
                off: {
                    avgPenaltyYards: 43,
                    oppg: 25.9,
                    thirdDown: 41.79,
                    toRatio: 1,
                    top: 29,
                    ypg: 355.7,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 199,
                    totalGames: 5,
                },
                favorite: {
                    avgML: -673.3333333333334,
                    totalGames: 6,
                },
            },
            recordData: {
                record: '0-0',
                ats: '0-0',
            },
            sos: 34,
        },
        gameId: '2022-01-04T20:00:00-06:00-912348-770',
        dateTime: '2022-01-04T20:00:00-06:00',
        status: 'not started',
        location: 'NRG Stadium, Houston, TX',
        openingSpread: 2.5,
        bookData: [
            {
                line: 8,
                label: 'Bet MGM',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                color: '#ae996a',
            },
            {
                line: 8.5,
                label: 'DraftKings',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                color: '#62af23',
            },
            {
                line: 8.5,
                label: 'FanDuel',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                color: '#2192f5',
            },
            {
                line: 8.5,
                label: 'Points Bet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-pointsbet.png',
                color: '#000',
            },
            {
                line: 8.5,
                label: 'Caesars',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-williamhill.png',
                color: '#183432',
            },
            {
                line: 8.5,
                label: 'Sugar House',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betrivers.png',
                color: '#18365e',
            },
            {
                line: 8.5,
                label: 'UniBet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-unibet.png',
                color: '#147b44',
            },
        ],
        weather: {
            forecast: {
                desc: 'Fair',
                temperature: '50°F',
                wind_dir: 'Calm',
            },
            location: 'NRG Stadium, 1 NRG Pkwy, Houston, Texas 77054, United States',
            address: 'NRG Stadium, Houston, TX',
        },
        headlines: [
            {
                title: 'LSU VS. KANSAS STATE: PREDICTION, PICK, TEXAS BOWL ODDS, SPREAD ...',
                href: 'https://www.google.com/url?q=https://www.cbssports.com/college-football/news/lsu-vs-kansas-state-prediction-pick-texas-bowl-odds-spread-live-stream-watch-online-tv-channel/&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAEQAg&usg=AOvVaw038k_GHFiYTfq7Nd30-kW8',
                description: '4 hours ago   The Tigers and Wildcats will square off on Tuesday night in the penultimate game of the college football season.',
            },
            {
                title: 'LSU VS KANSAS STATE: MERCARI TEXAS BOWL PREDICTION, GAME PREVIEW',
                href: 'https://www.google.com/url?q=https://collegefootballnews.com/2022/01/lsu-vs-kansas-state-texas-bowl-prediction-game-preview-how-to-watch-lines&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAoQAg&usg=AOvVaw2PQTsh6gjsnN8zTHIur7BW',
                description: '17 hours ago   LSU vs Kansas State: Mercari Texas Bowl prediction, game preview, how to watch, lines: Tuesday, January 4.',
            },
            {
                title: 'LSU VS. KANSAS STATE PREDICTION, PREVIEW, AND ODDS - 1-4-2022',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/ncaaf/1-4-2022/lsu-vs-kansas-state-prediction-3576/&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAkQAg&usg=AOvVaw200Xb8RyNh-ua2hstlvnFF',
                description:
                    '16 hours ago   Will LSU win the final game before Brian Kelly takes over as they take on Kansas State in the TaxAct Texas Bowl Tuesday night?',
            },
            {
                title: 'TEXAS BOWL PREDICTION AND PREVIEW: KANSAS STATE WILDCATS VS. LSU ...',
                href: 'https://www.google.com/url?q=https://athlonsports.com/college-football/texas-bowl-kansas-state-wildcats-vs-lsu-tigers-prediction-picks-2022&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAgQAg&usg=AOvVaw21L3idythcImQ_qooKj-ui',
                description:
                    '3 hours ago   Kansas State enters this game on the heels of tough losses to eventual Big 12 champion Baylor at home and Texas on the road. Despite holding ...',
            },
            {
                title: 'KANSAS STATE VS. LSU TEXAS BOWL ODDS, PLAYS AND INSIGHTS - SPORTS ...',
                href: 'https://www.google.com/url?q=https://www.si.com/betting/2022/01/03/kansas-state-wildcats-vs-lsu-tigers-texas-bowl-college-football-odds-plays-insights-1-4-2022&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAcQAg&usg=AOvVaw0oW5mH8RpkA9CQB4aRPF6W',
                description:
                    '3 hours ago   The Kansas State Wildcats will play the LSU Tigers in the Texas Bowl. ... Kansas State and its opponents have gone over the current 48-point ...',
            },
            {
                title: 'KANSAS STATE VS. LSU TEXAS BOWL ODDS, PLAYS AND INSIGHTS - SPORTS ...',
                href: 'https://www.google.com/url?q=https://www.si.com/betting/2021/12/31/kansas-state-wildcats-vs-lsu-tigers-texas-bowl-college-football-odds-plays-insights-1-4-2022&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAQQAg&usg=AOvVaw2AI600PiwM-n0WkdcbFPU-',
                description:
                    "3 days ago   Kansas State and its opponents have gone over the current 48-point total in seven of 12 games (58.3%) this season. In 63.6% of LSU's games ...",
            },
            {
                title: 'TEXAS BOWL: LSU FOOTBALL VS KANSAS STATE SCORE PREDICTION, SCOUTING ...',
                href: 'https://www.google.com/url?q=https://www.theadvertiser.com/story/sports/college/lsu/2022/01/03/lsu-football-kansas-state-texas-bowl-score-prediction-scouting-report/8947040002/&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAUQAg&usg=AOvVaw1bM4tFz1G9rIETpAl63-Zj',
                description:
                    "9 hours ago   Prediction. Kansas State 21, LSU 13: With LSU's offense in a state of flux at quarterback and Kansas State's offense not being the most ...",
            },
            {
                title: 'LSU VS KANSAS STATE ODDS, PICKS & PREDICTIONS - TEXAS BOWL',
                href: 'https://www.google.com/url?q=https://www.covers.com/ncaaf/lsu-vs-kansas-state-texas-bowl-odds-picks-predictions-january-4-2022&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAAQAg&usg=AOvVaw0wfengi9T89H0_by10ZfVL',
                description:
                    '3 days ago   LSU vs Kansas State predictions   Prediction: Kansas State -3.5 (-115)   Prediction: Under 48 (-110)   Best bet: Under 48 (-110).',
            },
            {
                title: 'LSU VS KANSAS STATE PREDICTION AND PICK FOR TEXAS BOWL COLLEGE ...',
                href: 'https://www.google.com/url?q=https://www.fanduel.com/theduel/posts/lsu-kansas-state-prediction-pick-texas-bowl-college-football-game-fanduel-sportsbook-01frc0qkrw8p&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAMQAg&usg=AOvVaw3FJSQapiO0LJky_qF87ZCt',
                description: '2 days ago   KSU vs LSU Prediction. Winner: Kansas State (3), LSU (1). Sign up for FanDuel ...',
            },
            {
                title: 'LSU TIGERS VS KANSAS STATE WILDCATS PREDICTIONS - PICKSWISE',
                href: 'https://www.google.com/url?q=https://www.pickswise.com/college-football/predictions/lsu-tigers-vs-kansas-state-wildcats-predictions/&sa=U&ved=2ahUKEwiL7KD7qpb1AhW7lGoFHVTSBdkQFnoECAIQAg&usg=AOvVaw0Vy2uLylW6s-Vqk4rYZe60',
                description: 'LSU Tigers vs Kansas State Wildcats Preview, Analysis and Predictions for their bowl game on January 4th at 9:00 PM EST.',
            },
        ],
        total: 47.5,
        totalStats: {
            total: 47.5,
            over: 4,
            under: 3,
        },
        advantages: [
            {
                adv: 'Line Movement',
                id: 'line-movement',
                description: 'The line is moving in favor of LSU.',
                teamId: 770,
            },
            {
                adv: 'Big Money',
                id: 'big-money',
                description: 'We are seeing larger bets coming in on KSU.',
                teamId: 912348,
            },
            {
                adv: 'Healthier',
                id: 'health',
                description: 'The KSU have less guys on the injury report.',
                teamId: 912348,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 63.62359550561798,
                teamId: 770,
                description: 'LSU has a better PPG.  Teams with a better PPG win at a 63% rate.',
                amount: 0.6000000000000014,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 64.26553672316385,
                teamId: 912348,
                description: 'KSU has a better DPPG.  Teams with a better DPPG win at a 64% rate.',
                amount: 6.100000000000001,
            },
            {
                adv: 'Key Factor - YPG',
                id: 'key-factor',
                factorId: 'ypg',
                winPercent: 59.60526315789474,
                teamId: 770,
                description: 'LSU has a better YPG.  Teams with a better YPG win at a 59% rate.',
                amount: 17.80000000000001,
            },
            {
                adv: 'Key Factor - toRatio',
                id: 'key-factor',
                factorId: 'toRatio',
                winPercent: 59.44540727902946,
                teamId: 912348,
                description: 'KSU has a better toRatio.  Teams with a better toRatio win at a 59% rate.',
                amount: 2,
            },
            {
                adv: 'Key Factor - 3rd Down %',
                id: 'key-factor',
                factorId: 'thirdDown',
                winPercent: 57.61154855643045,
                teamId: 912348,
                description: 'KSU has a better 3rd Down %.  Teams with a better 3rd Down % win at a 57% rate.',
                amount: 4.43,
            },
            {
                adv: 'Key Factor - Time of Poss.',
                id: 'key-factor',
                factorId: 'top',
                winPercent: 53.25443786982249,
                teamId: 0,
                description: '',
                amount: 0,
            },
            {
                adv: 'Key Factor - Avg. Penalty YPG',
                id: 'key-factor',
                factorId: 'avgPenaltyYards',
                winPercent: 43.292682926829265,
                teamId: 770,
                description: 'LSU has a better Avg. Penalty YPG.  Teams with a better Avg. Penalty YPG win at a 43% rate.',
                amount: 11,
            },
            {
                adv: 'Key Factor - RYPG',
                id: 'key-factor',
                factorId: 'rypg',
                winPercent: 62.95264623955432,
                teamId: 912348,
                description: 'KSU has a better RYPG.  Teams with a better RYPG win at a 62% rate.',
                amount: 11.000000000000014,
            },
            {
                adv: 'Key Factor - PYPG',
                id: 'key-factor',
                factorId: 'pypg',
                winPercent: 56.05006954102921,
                teamId: 912348,
                description: 'KSU has a better PYPG.  Teams with a better PYPG win at a 56% rate.',
                amount: 13.200000000000017,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'LSU has an advantage based on our power rank data.',
                teamId: 770,
                amount: 0,
            },
        ],
        prediction: {
            awayScore: 24.16,
            homeScore: 29.03,
            winner: 912348,
            rating: 3.27,
            spreadWinner: 770,
            powerSpread: 4.870000000000001,
            powerMoneyline: {
                dogML: 205,
                favML: -225,
            },
            powerLineWinner: 770,
        },
        situations: [],
    },
    cbb: {
        awayTeam: {
            teamName: 'Southern Miss',
            teamId: 7713898,
            abbr: 'USM',
            mascot: '',
            fullName: 'Southern Mississippi Golden Eagles',
            shortName: 'Southern Miss',
            pitcherData: '',
            color: '#FFD046',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/cbb%2F7713898_cfb.svg?alt=media&token=9fdb2f25-28f9-42cb-898b-577408162558',
            spread: 8.5,
            odds: {
                fullGame: {
                    moneyline: 290,
                    spread: 8.5,
                },
            },
            spreadPercent: 51,
            mlPercent: 59,
            mlMoneyPercent: 23,
            spreadMoneyPercent: 61,
            injuries: [
                {
                    player: 'T.\n                                                                                    Hardy',
                    position: 'G',
                    date: 'for season ',
                    status: 'out',
                    desc: ' Shoulder',
                },
            ],
            openMoneyLine: 270,
            moneyLine: 290,
            stats: {
                def: {
                    defEff: 1.051,
                    fouls: 16.2,
                    oppThreePoint: 34.3,
                    ppg: 72.4,
                    reboundPercent: 32.8,
                },
                off: {
                    assists: 12.6,
                    astTo: 0.89,
                    floorPercent: 41.5,
                    freeThrow: 67.7,
                    offEff: 0.87,
                    oppg: 59.9,
                    paceofplay: 269,
                    threePoint: 30.6,
                    turnovers: 14.2,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 500,
                    totalGames: 14,
                },
                favorite: {
                    avgML: -455,
                    totalGames: 3,
                },
            },
            recordData: {
                ats: ' 7-11-0',
                awayRecord: '2-9',
                homeRecord: '4-5',
                ou: '22-18-1',
                record: '6-14',
            },
            sos: 253,
        },
        homeTeam: {
            teamName: 'FIU',
            teamId: 23787,
            abbr: 'FIU',
            mascot: '',
            fullName: 'Florida International Panthers',
            shortName: 'Florida Int.',
            pitcherData: '',
            color: '#081E3F',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/cbb%2F23787_cfb.svg?alt=media&token=12aae9f5-64fc-4b48-a307-6ca80a4a0939',
            spread: -8.5,
            spreadPercent: 49,
            mlPercent: 41,
            mlMoneyPercent: 77,
            spreadMoneyPercent: 39,
            injuries: [],
            openMoneyLine: -350,
            moneyLine: -360,
            odds: {
                fullGame: {
                    moneyline: -360,
                    spread: -8.5,
                },
            },
            stats: {
                def: {
                    defEff: 0.969,
                    fouls: 16.3,
                    oppThreePoint: 30.7,
                    ppg: 69.7,
                    reboundPercent: 36.3,
                },
                off: {
                    assists: 14.8,
                    astTo: 1.04,
                    floorPercent: 43,
                    freeThrow: 69.6,
                    offEff: 0.957,
                    oppg: 68.8,
                    paceofplay: 109,
                    threePoint: 33,
                    turnovers: 14.2,
                },
            },
            averageMoneylines: {
                dog: {
                    avgML: 327.77777777777777,
                    totalGames: 9,
                },
                favorite: {
                    avgML: -197.85714285714286,
                    totalGames: 7,
                },
            },
            recordData: {
                ats: ' 7-11-1',
                awayRecord: '1-8',
                homeRecord: '11-1',
                ou: '22-18-1',
                record: '12-9',
            },
            sos: 270,
        },
        gameId: '2022-02-03T18:00:00-06:00-23787-7713898',
        dateTime: '2022-02-03T18:00:00-06:00',
        status: 'not started',
        location: 'Ocean Bank Convocation Center, Miami, FL',
        openingSpread: 8,
        bookData: [
            {
                line: 8,
                label: 'Bet MGM',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                color: '#ae996a',
            },
            {
                line: 8.5,
                label: 'DraftKings',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                color: '#62af23',
            },
            {
                line: 8.5,
                label: 'FanDuel',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-fanduel.png',
                color: '#2192f5',
            },
            {
                line: 8.5,
                label: 'Points Bet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-pointsbet.png',
                color: '#000',
            },
            {
                line: 8.5,
                label: 'Caesars',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-williamhill.png',
                color: '#183432',
            },
            {
                line: 8.5,
                label: 'Sugar House',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betrivers.png',
                color: '#18365e',
            },
            {
                line: 8.5,
                label: 'UniBet',
                logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-unibet.png',
                color: '#147b44',
            },
        ],
        headlines: [
            {
                title: 'SOUTHERN MISS VS. FLORIDA INTERNATIONAL (FIU) PREDICTION, PREVIEW ...',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/ncaab/2-3-2022/southern-miss-vs-florida-international-fiu-prediction-502/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAYQAg&usg=AOvVaw3hFk6ZEC9ofA2daUlHUoX0',
                description:
                    '20 hours ago   The Golden Eagles are coming off a 76-62 loss to Rice as 5.5-point underdogs. The Panthers are coming off a 79-68 loss to UTEP as seven-point ...',
            },
            {
                title: 'SOUTHERN MISS VS. FLORIDA INTERNATIONAL (FIU) - PREDICTIONS',
                href: 'https://www.google.com/url?q=https://statsalt.com/games/ncaab/2-3-2022/southern-miss-vs-florida-international-fiu-prediction-9054/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAkQAg&usg=AOvVaw1uFfsvKiSAhqmX14Pl7nmm',
                description:
                    '14 hours ago   The Southern Mississippi Eagles get set to take on the Florida International Panthers with an overall record of 6-14.',
            },
            {
                title: 'FLORIDA INTERNATIONAL VS SOUTHERN MISSISSIPPI 2/3/22 COLLEGE ...',
                href: 'https://www.google.com/url?q=https://sportschatplace.com/college-basketball-picks/2022/02/02/florida-international-vs-southern-mississippi-2-3-22-college-basketball-picks-predictions-odds/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAcQAg&usg=AOvVaw2_QOP65Mbc30EdhWZITvd5',
                description:
                    '13 hours ago   The Southern Miss Golden Eagles and FIU Golden Panthers meet Thursday in college basketball action at the Ocean Bank Convocation Center.',
            },
            {
                title: 'SOUTHERN MISS AT FIU 2/3/22 - COLLEGE BASKETBALL PICKS & PREDICTIONS',
                href: 'https://www.google.com/url?q=https://freepicks.picksandparlays.net/more-free-picks/college-basketball-picks/southern-miss-at-fiu-2322-college-basketball-picks-and-predictions&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAgQAg&usg=AOvVaw00hnxNoNGfK9AQZZcgiGhL',
                description:
                    '17 hours ago   Game, FIU Panthers vs USM Golden Eagles. Date & Time, Thursday February 3, 2022, 7:00 PM (EST). Location. Ocean Bank Convocation Center.',
            },
            {
                title: 'SOUTHERN MISSISSIPPI GOLDEN EAGLES VS FLORIDA INTERNATIONAL PANTHERS',
                href: 'https://www.google.com/url?q=https://scores24.live/en/basketball/m-04-02-2022-southern-mississippi-golden-eagles-fiu-panthers-prediction&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAUQAg&usg=AOvVaw10NSYm7SBcen2O64Yo0cYL',
                description:
                    '11 hours ago   In the current season, the team plays at a high level: 3 wins and 7 loses allows Southern Mississippi Golden Eagles gain 63.16% victory and get ...',
            },
            {
                title: 'FLORIDA INTERNATIONAL VS. SOUTHERN MISS - COLLEGE BASKETBALL',
                href: 'https://www.google.com/url?q=https://www.sportsgamblingpodcast.com/2022/02/02/florida-international-vs-southern-miss-college-basketball-predictions-betting-lines-odds-and-trends/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAEQAg&usg=AOvVaw0rsh-0hP7M-XrEVyWOq8ul',
                description:
                    '15 hours ago   The 72 points per game the Panthers score are only one more point than the Golden Eagles allow (71). When Florida International scores more than ...',
            },
            {
                title: 'FLORIDA INTERNATIONAL VS SOUTHERN MISS BETTING ODDS - THELINES.COM',
                href: 'https://www.google.com/url?q=https://www.thelines.com/florida-international-southern-miss-ncaa-basketball-preview-prediction-2-3-2022/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAMQAg&usg=AOvVaw2ZIn5eTKZqsk2GfmYSwFKq',
                description:
                    "17 hours ago   Keep reading to find betting insights and statistics to help place a wager on the Panthers' ( to win on the moneyline) game versus the Golden ...",
            },
            {
                title: 'SOUTHERN MISS GOLDEN EAGLES VS FLORIDA INTERNATIONAL GOLDEN ...',
                href: 'https://www.google.com/url?q=https://www.sportsbettingdime.com/college-basketball/odds/matchup/1043070/&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAAQAg&usg=AOvVaw2gzI4rTjzfCZiw-3873TzI',
                description: '19 hours ago   Southern Miss Golden Eagles (6-14 SU, 5-11 ATS) vs Florida International Golden Panthers (12-9 SU, 7-11-1 ATS).',
            },
            {
                title: 'FIU PANTHERS VS SOUTHERN MISS GOLDEN EAGLES: PREVIEW & PREDICTION ...',
                href: 'https://www.google.com/url?q=https://www.underdogdynasty.com/2021/11/26/22802623/fiu-panthers-vs-southern-miss-golden-eagles-preview-prediction-tv-radio-college-football-cfb&sa=U&ved=2ahUKEwjsw_OUiuT1AhW4lGoFHRqCDh8QFnoECAIQAg&usg=AOvVaw2Adrm294bfHopqMXsrxwss',
                description:
                    "Nov 26, 2021   FIU Panthers vs Southern Miss Golden Eagles: Preview & Prediction, TV, Radio. Will Hall looks to end Southern Miss' season on a two-game win ...",
            },
        ],
        total: 134.5,
        totalStats: {
            total: 134.5,
            over: 30,
            under: 50,
        },
        advantages: [
            {
                adv: 'Line Movement',
                id: 'line-movement',
                description: 'The line is moving in favor of USM.',
                teamId: 7713898,
            },
            {
                adv: 'Big Money',
                id: 'big-money',
                description: '',
                teamId: 0,
            },
            {
                adv: 'Healthier',
                id: 'health',
                description: 'The FIU have less guys on the injury report.',
                teamId: 23787,
            },
            {
                adv: 'Key Factor - AST:TO%',
                id: 'key-factor',
                factorId: 'astTo',
                winPercent: 60.328997704667174,
                teamId: 23787,
                description: 'FIU has a better AST:TO%.  Teams with a better AST:TO% win at a 60% rate.',
                amount: 0.15000000000000002,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 60.93692364441166,
                teamId: 23787,
                description: 'FIU has a better PPG.  Teams with a better PPG win at a 60% rate.',
                amount: 8.899999999999999,
            },
            {
                adv: 'Key Factor - Floor %',
                id: 'key-factor',
                factorId: 'floorPercent',
                winPercent: 65.62384983437614,
                teamId: 23787,
                description: 'FIU has a better Floor %.  Teams with a better Floor % win at a 65% rate.',
                amount: 1.5,
            },
            {
                adv: 'Key Factor - oEff%',
                id: 'key-factor',
                factorId: 'offEff',
                winPercent: 65.58282208588957,
                teamId: 23787,
                description: 'FIU has a better oEff%.  Teams with a better oEff% win at a 65% rate.',
                amount: 0.08699999999999997,
            },
            {
                adv: 'Key Factor - 3 Point %',
                id: 'key-factor',
                factorId: 'threePoint',
                winPercent: 57.265569076592705,
                teamId: 23787,
                description: 'FIU has a better 3 Point %.  Teams with a better 3 Point % win at a 57% rate.',
                amount: 2.3999999999999986,
            },
            {
                adv: 'Key Factor - Free Throw %',
                id: 'key-factor',
                factorId: 'freeThrow',
                winPercent: 53.97769516728624,
                teamId: 23787,
                description: 'FIU has a better Free Throw %.  Teams with a better Free Throw % win at a 53% rate.',
                amount: 1.8999999999999915,
            },
            {
                adv: 'Key Factor - Rebound %',
                id: 'key-factor',
                factorId: 'reboundPercent',
                winPercent: 57.00410907732536,
                teamId: 23787,
                description: 'FIU has a better Rebound %.  Teams with a better Rebound % win at a 57% rate.',
                amount: 3.5,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 62.95888399412628,
                teamId: 23787,
                description: 'FIU has a better DPPG.  Teams with a better DPPG win at a 62% rate.',
                amount: 2.700000000000003,
            },
            {
                adv: 'Key Factor - dEff%',
                id: 'key-factor',
                factorId: 'defEff',
                winPercent: 35.68281938325991,
                teamId: 7713898,
                description: 'USM has a better dEff%.  Teams with a better dEff% win at a 35% rate.',
                amount: 0.08199999999999996,
            },
            {
                adv: 'Key Factor - Fouls PG',
                id: 'key-factor',
                factorId: 'fouls',
                winPercent: 53.95017793594305,
                teamId: 7713898,
                description: 'USM has a better Fouls PG.  Teams with a better Fouls PG win at a 53% rate.',
                amount: 0.10000000000000142,
            },
            {
                adv: 'Key Factor - Opp. 3 Point %',
                id: 'key-factor',
                factorId: 'oppThreePoint',
                winPercent: 56.59025787965616,
                teamId: 23787,
                description: 'FIU has a better Opp. 3 Point %.  Teams with a better Opp. 3 Point % win at a 56% rate.',
                amount: 3.599999999999998,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'FIU has an advantage based on our power rank data.',
                teamId: 23787,
                amount: 0,
            },
        ],
        prediction: {
            awayScore: 59.66,
            homeScore: 82.25,
            rating: 4.17,
            winner: 23787,
            spreadWinner: 23787,
            powerSpread: 22.590000000000003,
            powerMoneyline: {
                dogML: 407,
                favML: -407,
            },
            powerLineWinner: 23787,
        },
        weather: {},
        situations: [],
    },
    nhl: {
        awayTeam: {
            teamName: 'Rangers',
            teamId: 13,
            abbr: 'NYR',
            mascot: 'Rangers',
            fullName: 'New York Rangers',
            shortName: 'NY Rangers',
            pitcherData: '',
            color: '#0038A8',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nhl%2F13_nhl.svg?alt=media&token=033e8dc7-5632-4b67-8821-05cbc113aed3',
            spread: 1.5,
            odds: {
                fullGame: {
                    moneyline: 115,
                    spread: 1.5,
                },
                half: {
                    spread: 0.5,
                    moneyline: 115,
                    total: 5.5,
                },
            },
            spreadPercent: 52,
            mlPercent: 47,
            mlMoneyPercent: 62,
            spreadMoneyPercent: 41,
            openMoneyLine: 110,
            moneyLine: 115,
            stats: {
                def: {
                    ppg: 2.56,
                    scsvPercent: 5,
                },
                off: {
                    oppg: 3.3,
                    pp: 24.08,
                    sog: 31.4,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 120.86666666666666,
                    totalGames: 15,
                },
                favorite: {
                    avgML: -181.73333333333332,
                    totalGames: 60,
                },
            },
            recordData: {
                ats: '44-38',
                awayRecord: '24-9-8',
                conferenceRecord: '0-0',
                divisionRecord: '0-0',
                homeRecord: '23-13-5',
                ou: '37-44-2',
                record: '47-22',
            },
            sos: 7,
            strength: 0,
            division: 'Metropolitan',
            conference: 'Eastern Conference',
        },
        homeTeam: {
            teamName: 'New Jersey',
            teamId: 93124,
            abbr: 'NJ',
            mascot: 'Devils',
            fullName: 'New Jersey Devils',
            shortName: 'NJD',
            pitcherData: '',
            color: '#CE1126',
            logo: 'https://firebasestorage.googleapis.com/v0/b/line-prophet.appspot.com/o/nhl%2F93124_nhl.svg?alt=media&token=bbf8324e-5e32-4e2c-be33-a2d84a43c3fe',
            spread: -1.5,
            odds: {
                fullGame: {
                    moneyline: -135,
                    spread: -1.5,
                },
                half: {
                    spread: -0.5,
                    moneyline: -115,
                    total: 5.5,
                },
            },
            spreadPercent: 48,
            mlPercent: 53,
            mlMoneyPercent: 38,
            spreadMoneyPercent: 59,
            openMoneyLine: -130,
            moneyLine: -135,
            stats: {
                def: {
                    ppg: 2.57,
                    scsvPercent: 9,
                },
                off: {
                    oppg: 3.5,
                    pp: 21.88,
                    sog: 34.3,
                },
                splits: {},
            },
            averageMoneylines: {
                dog: {
                    avgML: 116.15384615384616,
                    totalGames: 13,
                },
                favorite: {
                    avgML: -181.234375,
                    totalGames: 64,
                },
            },
            recordData: {
                ats: '42-40',
                awayRecord: '28-9-4',
                conferenceRecord: '0-0',
                divisionRecord: '0-0',
                homeRecord: '24-13-4',
                ou: '42-37-4',
                record: '52-22',
            },
            sos: 8,
            strength: 0,
            division: 'Metropolitan',
            conference: 'Eastern Conference',
        },
        gameId: '2023-04-20T19:30:00-05:00-93124-13',
        dateTime: '2023-04-20T19:30:00-05:00',
        status: 'not started',
        bookData: {
            moneylines: [
                {
                    awayLine: 110,
                    homeLine: -130,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: 115,
                    homeLine: -135,
                    label: 'Caesars',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-williamhill.png',
                    color: '#183432',
                },
                {
                    awayLine: 115,
                    homeLine: -135,
                    label: 'Points Bet',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-pointsbet.png',
                    color: '#000',
                },
                {
                    awayLine: 115,
                    homeLine: -135,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
                {
                    awayLine: 115,
                    homeLine: -135,
                    label: 'Wynn',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-wynnbet.png',
                    color: '#000',
                },
                {
                    awayLine: 114,
                    homeLine: -137,
                    label: 'UniBet',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-unibet.png',
                    color: '#147b44',
                },
            ],
            spreads: [
                {
                    awayLine: 1.5,
                    homeLine: -1.5,
                    label: 'Bet MGM',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-betmgm.png',
                    color: '#ae996a',
                },
                {
                    awayLine: 1.5,
                    homeLine: -1.5,
                    label: 'Caesars',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-williamhill.png',
                    color: '#183432',
                },
                {
                    awayLine: 1.5,
                    homeLine: -1.5,
                    label: 'Points Bet',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-pointsbet.png',
                    color: '#000',
                },
                {
                    awayLine: 1.5,
                    homeLine: -1.5,
                    label: 'DraftKings',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-draftkings.png',
                    color: '#62af23',
                },
                {
                    awayLine: 1.5,
                    homeLine: -1.5,
                    label: 'Wynn',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-wynnbet.png',
                    color: '#000',
                },
                {
                    awayLine: 1.5,
                    homeLine: -1.5,
                    label: 'UniBet',
                    logo: 'https://bctn-sao.s3.amazonaws.com/img/sportsbooks/icn-unibet.png',
                    color: '#147b44',
                },
            ],
        },
        openingSpread: 1.5,
        headlines: [
            {
                title: 'N.Y. RANGERS VS. NEW JERSEY DEVILS PREDICTION, PREVIEW, AND ODDS - 4-20-2023WINNERSANDWHINERS.COM\n23 HOURS AGO',
                href: 'https://www.google.com/url?q=https://winnersandwhiners.com/games/nhl/4-20-2023/ny-rangers-vs-new-jersey-devils-prediction-2016/&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQ0PADegQIChAD&usg=AOvVaw2wX_0hvzGVpFRzVIKRPh0m',
                description: 'USA TODAY Sportsbook Wire\n2 days ago',
            },
            {
                title: 'NEW YORK RANGERS AT NEW JERSEY DEVILS ODDS, PICKS AND PREDICTIONSUSA TODAY SPORTSBOOK WIRE\n18 HOURS AGO',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2023/04/19/new-york-rangers-at-new-jersey-devils-game-2-odds-picks-and-predictions/&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQ0PADegQIChAH&usg=AOvVaw3WkrBClCOuk4yuDO4t_KWR',
                description: 'USA TODAY Sportsbook Wire\n2 days ago',
            },
            {
                title: 'RANGERS VS DEVILS ODDS, PICKS, AND PREDICTIONS - NHL PLAYOFFS GAME 2COVERS.COM\n5 HOURS AGO',
                href: 'https://www.google.com/url?q=https://www.covers.com/nhl/rangers-vs-devils-game-2-picks-predictions-april-20-2023&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQ0PADegQIChAL&usg=AOvVaw2SMdAtSuHI3Svf1DF_LXF9',
                description: 'USA TODAY Sportsbook Wire\n2 days ago',
            },
            {
                title: "NEW YORK RANGERS VS NEW JERSEY DEVILS PREDICTION, 4/18/2023 NHL PICKS, BEST \nBETS & ODDSDOC'S SPORTS\n2 DAYS AGO",
                href: 'https://www.google.com/url?q=https://www.docsports.com/free-picks/nhl-hockey/2023/new-york-rangers-vs-new-jersey-devils-prediction-4-18-2023-nhl-picks-best-bets-odds.html&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQ0PADegQIChAP&usg=AOvVaw1o2DnqT0qggjagGIW4JJdI',
                description: 'USA TODAY Sportsbook Wire\n2 days ago',
            },
            {
                title: 'DEVILS VS RANGERS PREDICTION, ODDS & BEST BET FOR NHL PLAYOFFS GAME 1 (BACK \nJACK HUGHES TO SCORE IN PLAYOFF DEBUT)FANDUEL\n3 DAYS AGO',
                href: 'https://www.google.com/url?q=https://www.fanduel.com/theduel/posts/devils-vs-rangers-prediction-odds-best-bet-nhl-playoffs-game-1-101gy54pckpx4&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQ0PADegQIChAT&usg=AOvVaw2EXOwg5lSBM1QRg3jsVdjX',
                description: 'USA TODAY Sportsbook Wire\n2 days ago',
            },
            {
                title: "NEW YORK RANGERS VS NEW JERSEY DEVILS PREDICTION, 4/20/2023 NHL PICKS, BEST \nBETS & ODDSDOC'S SPORTS\n22 HOURS AGO",
                href: 'https://www.google.com/url?q=https://www.docsports.com/free-picks/nhl-hockey/2023/new-york-rangers-vs-new-jersey-devils-prediction-4-20-2023-nhl-picks-best-bets-odds.html&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQ0PADegQIChAX&usg=AOvVaw2fc4tzPiBrwrOI4TB4p2Db',
                description: 'USA TODAY Sportsbook Wire\n2 days ago',
            },
            {
                title: 'NEW YORK RANGERS AT NEW JERSEY DEVILS GAME 1 ODDS, PICKS AND PREDICTIONSUSA TODAY SPORTSBOOK WIRE\n2 DAYS AGO',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2023/04/18/new-york-rangers-at-new-jersey-devils-game-1-odds-picks-and-predictions/&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQ0PADegQIChAb&usg=AOvVaw0HNFmaQIyCNUw2N2_eBKZo',
                description: 'USA TODAY Sportsbook Wire\n2 days ago',
            },
            {
                title: 'NEW YORK RANGERS AT NEW JERSEY DEVILS ODDS, PICKS AND PREDICTIONSSPORTSBOOKWIRE.USATODAY.COM › 2023/04/19 › NEW-YORK-RANGERS-AT-NEW-JER...',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2023/04/19/new-york-rangers-at-new-jersey-devils-game-2-odds-picks-and-predictions/&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQFnoECAUQAg&usg=AOvVaw1AgwUaQrz9iYr9qAp1Fgn1',
                description:
                    "19 hours ago   Analyzing Thursday's New York Rangers at New Jersey Devils Game 2 odds and lines, with NHL expert picks, predictions and best bets.",
            },
            {
                title: 'NEW YORK RANGERS AT NEW JERSEY DEVILS GAME 1 ODDS, PICKS ...SPORTSBOOKWIRE.USATODAY.COM › 2023/04/18 › NEW-YORK-RANGERS-AT-NEW-JER...',
                href: 'https://www.google.com/url?q=https://sportsbookwire.usatoday.com/2023/04/18/new-york-rangers-at-new-jersey-devils-game-1-odds-picks-and-predictions/&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQFnoECAkQAg&usg=AOvVaw0aff3sVxnH8tRVsXOtLVGE',
                description:
                    '2 days ago   Devils odds, and make our expert NHL picks and predictions. The Rangers finished with 107 points to end up 3rd in the Metropolitan Division.',
            },
            {
                title: 'RANGERS VS DEVILS GAME 2 ODDS, PICKS, AND PREDICTIONS - COVERS.COMWWW.COVERS.COM › NHL › RANGERS-VS-DEVILS-GAME-2-PICKS-PREDICTIONS-APRIL-...',
                href: 'https://www.google.com/url?q=https://www.covers.com/nhl/rangers-vs-devils-game-2-picks-predictions-april-20-2023&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQFnoECAgQAg&usg=AOvVaw2E1cEuFB-xJWd7w8VXnDwK',
                description:
                    '6 hours ago   The New York Rangers and the New Jersey Devils had a very lopsided scoreboard in Game 1, as the Rangers skated to a 5-1 victory.',
            },
            {
                title: 'NEW YORK RANGERS VS NEW JERSEY DEVILS PREDICTION, 4/20/2023 NHL ...WWW.DOCSPORTS.COM › FREE PICKS › NHL PICKS',
                href: 'https://www.google.com/url?q=https://www.docsports.com/free-picks/nhl-hockey/2023/new-york-rangers-vs-new-jersey-devils-prediction-4-20-2023-nhl-picks-best-bets-odds.html&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQFnoECAMQAg&usg=AOvVaw3tbGXE2Vm1A3_FLAe4fYAt',
                description:
                    '22 hours ago   The moneyline on this contest has New York at +110 while New Jersey is at -130. The over/under is set at 6. The New York Rangers will head into ...',
            },
            {
                title: 'RANGERS VS. DEVILS PREDICTION AND ODDS FOR GAME 2 OF NHL PLAYOFFSFANSIDED.COM › 2023/04/19 › RANGERS-VS-DEVILS-PREDICTION-ODDS-GAME-2-NH...',
                href: 'https://www.google.com/url?q=https://fansided.com/2023/04/19/rangers-vs-devils-prediction-odds-game-2-nhl-playoffs/&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQFnoECAQQAg&usg=AOvVaw2KhQTsWTL5bC8pRwsHSu-i',
                description:
                    "1 day ago   If you looked at the stats for the game and didn't know the final score, you wouldn't guess the Rangers won by four goals. The Devils had the 5- ...",
            },
            {
                title: 'NEW YORK RANGERS VS NEW JERSEY DEVILS SERIES PREVIEW AND ...WWW.FOREVERBLUESHIRTS.COM › NEW-YORK-RANGERS-VS-NEW-JERSEY-DEVILS-SERIES...',
                href: 'https://www.google.com/url?q=https://www.foreverblueshirts.com/new-york-rangers-vs-new-jersey-devils-series-preview-and-prediction/&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQFnoECAEQAg&usg=AOvVaw1nEW_HmFD3RWjCgURE48PF',
                description:
                    "4 days ago   Rangers vs Devils Prediction ... In the end, it will come down to Igor Shesterkin and the Rangers experience from last season's run that will be ...",
            },
            {
                title: 'DEVILS VS. RANGERS PREDICTION & PICKS - NHL PLAYOFFS FIRST ROUND ...WWW.WYMT.COM › SPORTS › BETTING › 2023/04/20 › DEVILS-RANGERS-NHL-NHL-P...',
                href: 'https://www.google.com/url?q=https://www.wymt.com/sports/betting/2023/04/20/devils-rangers-nhl-nhl-playoffs-first-round-game-2-picks-predictions/&sa=U&ved=2ahUKEwjPgKDTnrn-AhWvm2oFHXd0CCQQFnoECAcQAg&usg=AOvVaw2T8fdXFhorBOVHmpjJw1sD',
                description:
                    "36 minutes ago   Rangers game with odds and picks on the moneyline, over/under and puck ... New Jersey Devils' Jack Hughes, center, shoots the puck past New ...",
            },
        ],
        total: 5.5,
        totalStats: {
            total: 5.5,
            over: 43,
            under: 47,
        },
        history: {
            winner: 13,
            spreadWinner: 13,
            dateTime: '2023-04-18T19:00:00-05:00',
            awayTeam: {
                teamName: 'Rangers',
                teamId: 13,
                score: 5,
                spread: 1.5,
                moneyLine: 0,
                color: '#0038A8',
            },
            homeTeam: {
                teamName: 'New Jersey',
                teamId: 93124,
                score: 1,
                spread: -1.5,
                moneyLine: 0,
                color: '#CE1126',
            },
        },
        advantages: [
            {
                adv: 'Line Movement',
                id: 'line-movement',
                description: 'The line is moving in favor of NYR.',
                teamId: 13,
            },
            {
                adv: 'Big Money',
                id: 'big-money',
                description: '',
                teamId: 0,
            },
            {
                adv: 'Healthier',
                id: 'health',
                description: '',
                teamId: 0,
            },
            {
                adv: 'Key Factor - PPG',
                id: 'key-factor',
                factorId: 'oppg',
                winPercent: 57.10549258936356,
                teamId: 93124,
                description: 'NJ has a better PPG.  Teams with a better PPG win at a 57% rate.',
                amount: 0.20000000000000018,
            },
            {
                adv: 'Key Factor - DPPG',
                id: 'key-factor',
                factorId: 'ppg',
                winPercent: 58.374792703150916,
                teamId: 13,
                description: 'NYR has a better DPPG.  Teams with a better DPPG win at a 58% rate.',
                amount: 0.009999999999999787,
            },
            {
                adv: 'Key Factor - SOG/G',
                id: 'key-factor',
                factorId: 'sog',
                winPercent: 61.26878130217028,
                teamId: 93124,
                description: 'NJ has a better SOG/G.  Teams with a better SOG/G win at a 61% rate.',
                amount: 2.8999999999999986,
            },
            {
                adv: 'Key Factor - PP%',
                id: 'key-factor',
                factorId: 'pp',
                winPercent: 54.30680885972108,
                teamId: 13,
                description: 'NYR has a better PP%.  Teams with a better PP% win at a 54% rate.',
                amount: 2.1999999999999993,
            },
            {
                adv: 'Key Factor - SCSV%',
                id: 'key-factor',
                factorId: 'scsvPercent',
                winPercent: 53.50370981038747,
                teamId: 93124,
                description: 'NJ has a better SCSV%.  Teams with a better SCSV% win at a 53% rate.',
                amount: 4,
            },
            {
                adv: 'Power Rank',
                id: 'power-rank',
                description: 'NJ has an advantage based on our power rank data.',
                teamId: 93124,
                amount: 43,
            },
        ],
        prediction: {
            awayScore: 3.22,
            homeScore: 6.48,
            winner: 93124,
            spreadWinner: 93124,
            powerSpread: 3.2600000000000002,
            powerMoneyline: {
                dogML: 168,
                favML: -178,
            },
            powerLineWinner: 93124,
            rating: 2.3,
        },
        weather: {},
        situations: [],
    },
};
