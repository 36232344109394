import styled from 'styled-components';
import { GRAY, GREEN, PRIMARY, RED, WHITE } from '../../utils/styleConstants';
import { motion } from 'framer-motion';

export const SidebarContainer = styled.div`
    position: fixed;
    top: ${props => (props.page === 'profile' ? '135px' : '140px')};
    right: 0px;
    display: flex;
    border-left: 0px;
    border-right: 0px;
    flex-direction: column;
    background-color: transparent;
    z-index: 99999;
    @media (max-width: 991px) {
        top: ${props => (props.page === 'profile' ? '170px' : '175px')} !important;
    }
`;

export const CollapseButton = styled.button`
    color: ${PRIMARY};
    background: ${WHITE};
    border: none;
    border-bottom: 1px solid #ddd;
    padding: 10px 0px;
    width: ${p => (p.collapse ? '45px' : 'auto')};
    margin-left: ${p => (p.collapse ? 'auto' : '0px')};
    opacity: 0.8;
    @media (max-width: 480px) {
        opacity: 0.65;
    }
    &:hover {
        cursor: pointer;
        opacity: 1;
        border-left: 2px solid ${GREEN};
    }
`;

export const TabContainer = styled(motion.div)`
    position: relative;
    display: flex;
    flex-direction: column;
    box-shadow: -2px 5px 5px ${GRAY};
    opacity: 0.8;
    @media (max-width: 480px) {
        opacity: 0.75;
    }
    &:hover {
        opacity: 1;
    }
`;

// background: transparent - the TabContainer background is white
export const Tab = styled.button`
    display: flex;
    flex-direction: column;
    align-items: center;
    background: ${WHITE};
    border: none;
    border-bottom: 1px solid #ddd;
    font-size: 10px;
    padding: 15px 0px;
    color: ${PRIMARY};
    border-left: 2px solid transparent;
    width: 80px;
    &:hover {
        cursor: pointer;
        border-left: 2px solid ${GREEN};
    }
    svg {
        font-size: 24px;
        margin-bottom: 10px;
    }
`;

export const BackDrop = styled.div`
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: -1;
    top: 0;
    right: 0;
`;
