// Library imports
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'react-bootstrap';
// Component imports
import { LoadingSpinner } from '../../../../../components/page_components/LoadingSpinner';
import Table from '../../../../../components/page_components/TableV2';
// Action imports
import { fetchLeague } from '../../../../../store/slices/appSlice';
// Styled components
import { ConferenceWrapper, DivisionCard, DivisionWrapper, StandingsTitle } from './styles';
// Util imports
import { shortName } from '../../../../../utils';

const StandingsDrawer = () => {
    // Redux
    const { loaders, sport, leagueData, teams: reduxTeams } = useSelector(state => state.app);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(fetchLeague());
    }, []);

    const renderStandings = () => {
        var html = [];
        if (!leagueData?.standings) return html;
        if (sport === 'mlb' || sport === 'nfl' || sport === 'nba' || sport === 'nhl') {
            const conferences = Object.keys(leagueData.standings);
            conferences.forEach(conference => {
                const conferenceData = leagueData.standings[conference];
                const divisions = Object.keys(conferenceData);
                const divisionHtml = [];
                divisions.forEach(division => {
                    const teams = conferenceData[division];
                    if (!Array.isArray(teams)) return;
                    divisionHtml.push(
                        <DivisionCard>
                            <Card.Body>
                                <Card.Title>{division}</Card.Title>
                                <Card.Subtitle className="mb-2 text-muted">{conference}</Card.Subtitle>
                            </Card.Body>
                            <Table
                                dataList={teams.map((team, i) => {
                                    const teamData = reduxTeams.find(reduxTeam => reduxTeam.teamId === team.teamId);
                                    return {
                                        rank: i + 1,
                                        team: shortName(team),
                                        record: `${team.wins}-${team.losses}`,
                                        'games-back': team.gb,
                                        imgSrc: teamData?.logoUrl ? teamData.logoUrl : '',
                                    };
                                })}
                                autoHeight={true}
                                bordered
                                cellBordered
                                columns={[
                                    { header: 'Rank', dataKey: 'rank', flexGrow: 1 },
                                    { header: 'Team', dataKey: 'team', flexGrow: 2, minWidth: 150 },
                                    { header: 'Record', dataKey: 'record', flexGrow: 1 },
                                    { header: 'GB', dataKey: 'games-back', flexGrow: 1 },
                                ]}
                            />
                        </DivisionCard>,
                    );
                });
                html.push(
                    <ConferenceWrapper>
                        <StandingsTitle>{conference}</StandingsTitle>
                        <DivisionWrapper>{divisionHtml}</DivisionWrapper>
                    </ConferenceWrapper>,
                );
            });
        }

        if (sport === 'cfb') {
            if (Array.isArray(leagueData.standings)) {
                html.push(
                    <ConferenceWrapper>
                        <StandingsTitle>AP Top 25</StandingsTitle>
                        <Table
                            dataList={leagueData.standings.map((team, i) => {
                                const teamData = reduxTeams.find(reduxTeam => reduxTeam.teamId === team.teamId);
                                return {
                                    rank: i + 1,
                                    team: team.teamName,
                                    record: team.record,
                                    imgSrc: teamData?.logoUrl ? teamData.logoUrl : '',
                                };
                            })}
                            autoHeight={true}
                            bordered
                            cellBordered
                            columns={[
                                { header: 'Rank', dataKey: 'rank', flexGrow: 1 },
                                { header: 'Team', dataKey: 'team', flexGrow: 2 },
                                { header: 'Record', dataKey: 'record', flexGrow: 1 },
                            ]}
                        />
                    </ConferenceWrapper>,
                );
            }
        }

        return html;
    };

    if (loaders.find(loader => loader === 'league-data')) {
        return (
            <div>
                <LoadingSpinner loading={true} />
            </div>
        );
    }

    return <div>{renderStandings()}</div>;
};

export default StandingsDrawer;
